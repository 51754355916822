import {
  FETCH_ADVERTISER_LIST,
  FETCH_REQUEST,
  FETCH_CAMPAIGN_LIST,
  FETCH_ERROR,
  SET_ADVERTISER,
  FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
  SET_CAMPAIGN_LIST_FOR_REPORTING,
  SET_CAMPIGN_LIST_SELECTED,
  FETCH_ADVERTISER_REQUEST,
  FETCH_CAMPAIGN_ERROR,
  FETCH_ADVERTISER_ERROR,
  FETCH_ANDROID_CAMPAIGN_COUNT,
  FETCH_IOS_CAMPAIGN_COUNT,
  RESET_CAMPAIGN_COUNT,
  FETCH_ORG_LIST,
  SET_ADVERTISER_FILTERED_DATA,
  FETCHED_USER_ROLE
} from './advertiserTypes';

const initialState = {
  loading: false,

  advertiser_list: [],
  advertiser_guid: '',
  advertiser_name: '',
  campaign_list: [],
  adv_campaign_list: [],
  selected_campaigns: [],
  sfId: '',
  advertiser_campaign_fetching: false,
  campaign_list_error_msg: '',
  user_not_onboarded: false,
  ios_campaign_count: null,
  android_campaign_count: null,
  user_type: '',
  org_list: [],
  timeZone: 'UTC',
  platform: [],
  filtered_advertiser_list: []
};

const advertiserReducer = (state = initialState, action) => {
  //console.log('<><><<', action.type, action.payload);

  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        advertiser_list: []
      };
    case FETCH_ADVERTISER_REQUEST: {
      return {
        ...state,
        advertiser_campaign_fetching: true,
        selected_campaigns: [],
        campaign_list: []
      };
    }
    case FETCH_ADVERTISER_LIST:
      return {
        ...state,
        loading: false,
        advertiser_list: action.payload.advertiser_list.sort((a, b) => a.advertiser_name.localeCompare(b.advertiser_name)),
        user_not_onboarded: false,
        advertiser_campaign_fetching: false
        // user_type: action.payload.user_type,
      };
    case FETCHED_USER_ROLE:
      return {
        ...state,
        user_type: action.payload.user_type
      };
    case FETCH_CAMPAIGN_LIST:
      return {
        ...state,
        loading: false,
        campaign_list: action.payload
      };
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        campaign_list: []
      };
    case FETCH_ADVERTISER_ERROR:
      return {
        ...state,
        loading: false,
        advertiser_list: [],

        user_not_onboarded: true,
        msg: 'User Not Onboarded'
      };
    case FETCH_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        campaign_list: [],
        selected_campaigns: [],
        campaign_list_error_msg: action.payload,
        advertiser_campaign_fetching: false,
        android_campaigns_available: false
      };
    case SET_ADVERTISER:
      return {
        ...state,
        advertiser_guid: action.payload.advertiser_guid,
        advertiser_name: action.payload.advertiser_name,
        sfId: action.payload.sfId,
        timeZone: action.payload.timeZone,
        startDate: action.payload.startDate,
        platform: action.payload.platform,
        campaign_list_rest: true
      };
    case FETCH_CAMPAIGN_LIST_BY_ADVERTISER:
      return {
        ...state,

        loading: false,
        campaign_list: action.payload.campaign_list,
        selected_campaigns: action.payload.default_selected_campaigns,
        campaign_list_rest: false,
        sfId: action.payload.sfId,
        advertiser_campaign_fetching: false,
        campaign_list_error_msg: ''
      };
    case SET_CAMPAIGN_LIST_FOR_REPORTING:
      return {
        ...state,
        selected_campaigns: action.payload
      };
    case SET_CAMPIGN_LIST_SELECTED: {
      return {
        ...state,
        selected_campaigns: action.payload
      };
    }
    case FETCH_IOS_CAMPAIGN_COUNT: {
      return {
        ...state,
        ios_campaign_count: action.payload
      };
    }
    case FETCH_ANDROID_CAMPAIGN_COUNT: {
      return {
        ...state,
        android_campaign_count: action.payload
      };
    }
    case RESET_CAMPAIGN_COUNT: {
      return {
        ...state,
        ios_campaign_count: null,
        android_campaign_count: null
      };
    }
    case FETCH_ORG_LIST: {
      return {
        ...state,
        org_list: action.payload
      };
    }
    case SET_ADVERTISER_FILTERED_DATA: {
      return {
        ...state,
        filtered_advertiser_list: action.payload
      };
    }
    default:
      return state;
  }
};
export default advertiserReducer;
