import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MainCard from 'ui-component/cards/MainCard';
import LoaderCircular from 'ui-component/LoaderCircular';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },

  {
    field: 'campaign_name',
    headerName: 'Campaign Name',
    // flex: 1.5,
    width: 350,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },

  {
    field: 'country',
    headerName: 'Country',
    width: 110,
    align: 'center',
    headerAlign: 'center',
    // flex: 1.5,
    renderCell: (params) => <div className="country_name_settings">{params.value}</div>
  },
  {
    field: 'raw_event_name',
    headerName: 'Raw Event Name',
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'raw_attributed_events',
    headerName: 'Raw Attributed Events',
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  }
];
function splitDigits(num) {
  return Number(num).toLocaleString();
}
function roundToTwoDecimal(value) {
  if (value == '-999') return '-';
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
const RawEventNonSkanView = ({ rawEventData, loading, error }) => {
  useEffect(() => {
    //console.log(rawEventData);
  }, [rawEventData]);
  return (
    <>
      {' '}
      {loading ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Raw Event Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <div style={{ height: '200px', width: '100%' }}>
                {' '}
                <LoaderCircular show={true} />
              </div>
            </Grid>
          </Grid>
        </MainCard>
      ) : rawEventData == 0 || rawEventData.length == 0 ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Raw Event Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography style={{ textAlign: 'center' }}>
                {/* Please click <b>Apply Filter</b> to view data */}
                {error.length != 0 ? (
                  'No Data Found'
                ) : (
                  <span>
                    Please click <b>Apply Filter</b> to view data
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Raw Event Report</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <Box>
                    <div style={{ minHeight: '100%', width: '100%' }}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            position: 'relative',
                            top: '50%',
                            left: '50%'
                          }}
                        />
                      ) : (
                        <DataGrid
                          rows={rawEventData}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10
                              }
                            }
                          }}
                          components={{
                            LoadingOverlay: CircularProgress
                          }}
                          loading={loading}
                          pageSizeOptions={[10, 20, 30]}
                          slots={{ toolbar: GridToolbar }}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableDensitySelector
                          style={{ minHeight: '100%', width: '100%' }}
                          sortModel={[
                            {
                              field: 'installs',
                              sort: 'desc' // Set 'desc' for descending, 'asc' for ascending
                            }
                          ]}
                          autoHeight
                          //getRowHeight={getRowHeight}
                          getRowHeight={() => 'auto'}
                          className="custom-datagrid"
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    rawEventData: state.rawEventReducer.rawEventData,
    loading: state.rawEventReducer.loading,
    error: state.rawEventReducer.error
  };
};
export default connect(mapStateToProps, null)(RawEventNonSkanView);
