import React, { useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { Grid, Modal, Box, Typography } from '@mui/material';
import UserList from '../components/UserMapping/UserList';
const TimeZonePage = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} md={12} sm={6} xs={12}>
            Time Zone Mappings
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <UserList />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TimeZonePage;
