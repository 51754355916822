import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const Reporting = Loadable(lazy(() => import('pages/Reporting')));
// const AdvertiserPage = Loadable(lazy(() => import('pages/Advertiser')));
import Reporting from '../pages/Reporting';
import AdvertiserPage from '../pages/Advertiser';
import Creative from '../pages/Creative';
import AdminPanel from '../pages/AdminPanel';
import RawEventsPage from '../pages/RawEventsPage';
import WowPage from '../pages/WowPage';
import IAPPage from '../pages/IapPage';
// import Dashboard from '../pages/Dashboard'
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/reporting',
      element: <Reporting />
    },
    {
      path: '/advertisers',
      element: <AdvertiserPage />
    },
    {
      path: '/creative',
      element: <Creative />
    },
    {
      path: '/rawevents',
      element: <RawEventsPage />
    },
    {
      path: '/wow',
      element: <WowPage />
    },
    {
      path: '/iap',
      element: <IAPPage />
    }
    // {
    //   path: "/pages/admin",
    //   element: <AdminPanel />,
    // }
  ]
};

export default MainRoutes;
