// assets
import { IconDashboard, IconBrandChrome, IconStack, IconReportAnalytics, IconTimeline, IconFileAnalytics, IconFilePower, IconFileTime, IconGauge } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconBrandChrome,
  IconStack,
  IconReportAnalytics,
  IconTimeline,
  IconFileAnalytics,
  IconFilePower,
  IconFileTime,
  IconGauge
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  children: [
    {
      id: 'dsp-header',
      title: 'InMobi DSP',
      type: 'header' // Add this as a header for DSP items
    },
    {
      id: 'default',
      title: 'Overview',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconGauge,
      breadcrumbs: false
    },
    {
      id: 'dsp',
      title: 'DSP Overview',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconGauge,
      breadcrumbs: false
    },
    {
      id: 'reporting',
      title: 'Publisher App Report',
      type: 'item',
      url: '/reporting',
      icon: icons.IconReportAnalytics,
      breadcrumbs: false
    },
    {
      id: 'creative',
      title: 'Creative Report',
      type: 'item',
      url: '/creative',
      icon: icons.IconFileAnalytics,
      breadcrumbs: false
    },
    {
      id: 'rawevents',
      title: 'Raw Event Report',
      type: 'item',
      url: '/rawevents',
      icon: icons.IconFilePower,
      breadcrumbs: false
    },
    {
      id: 'wow',
      title: 'WoW Report',
      type: 'item',
      url: '/wow',
      icon: icons.IconFileTime,
      breadcrumbs: false
    },
    {
      id: 'iap-header',
      title: 'IAP',
      type: 'header' // Add this as a header for IAP items
    },
    {
      id: 'iap',
      title: 'IAP Overview',
      type: 'item',
      url: '/iap',
      icon: icons.IconDashboard,
      breadcrumbs: false
    }
    // {
    //   id: "admin",
    //   title: "Admin Panel",
    //   type: "item",
    //   url: "/pages/admin",
    //   icon: icons.IconBrandAsana,
    //   breadcrumbs: false,
    // }
  ]
};

export default dashboard;
