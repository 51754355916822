import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, isCollapsed }) => {
  const theme = useTheme();

  const items = item.children?.map((menu) => {
    // Check if the current menu item is a header
    if (menu.type === 'header') {
      return (
        <div key={menu.id}>
          <Typography
            variant="caption"
            sx={{
              ...theme.typography.menuCaption,
              // display: isCollapsed ? 'none' : 'block', // Hide when collapsed
              textAlign: isCollapsed ? 'center' : 'left', // Align center when collapsed
              transition: theme.transitions.create('display', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
              }),
              mt: 2,
              // textTransform: 'uppercase',
              fontWeight: 'bold'
            }}
            gutterBottom
          >
            {menu.title}
          </Typography>

          {/* Divider line under the header */}
          <Divider
            sx={{
              // display: isCollapsed ? 'none' : 'block', // Hide when collapsed
              mt: 0.5,
              mb: 1
            }}
          />
        </div>
      );
    }

    // Continue rendering collapse or item
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} isCollapsed={isCollapsed} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} isCollapsed={isCollapsed} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title &&
          !isCollapsed && (
            <Typography
              variant="caption"
              sx={{
                ...theme.typography.menuCaption,
                display: isCollapsed ? 'none' : 'block', // Hide when collapsed
                transition: theme.transitions.create('display', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                })
              }}
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  sx={{
                    ...theme.typography.menuCaption,
                    ...theme.typography.subMenuCaption,
                    display: isCollapsed ? 'none' : 'block', // Hide when collapsed
                    transition: theme.transitions.create('display', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen
                    })
                  }}
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
      {/* Admin Panel logic */}
      {/* {!isCollapsed && (
         <div className="MuiListItemText-root css-sqh3xd">
           <h5 className="MuiTypography-root MuiTypography-h5 css-51inbr">
             Admin Panel
           </h5>
         </div>
       )} */}

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
  isCollapsed: PropTypes.bool
};

export default NavGroup;
