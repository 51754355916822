import { useRoutes } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect } from 'react';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import { loginUser } from '../redux/skanRateCardStore/skanRateCardStoreAction';
// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = ({ experimentList, loginUserId, email, user_role }) => {
  useEffect(() => {
    console.log(email);
    let isUserLoggedIn = true; //localStorage.getItem("loggedIn");
    //console.log("Is User logged In-->",isUserLoggedIn)
    if (isUserLoggedIn == 'true') {
      //console.log(" proper")
      loginUserId(true);
    } else {
      //console.log(" Improper")
      loginUserId(false);
    }
  }, []);
  console.log('LIST is---->', experimentList, user_role);
  let routesList = [LoginRoutes, MainRoutes];
  if (user_role == 'admin') {
    routesList = [MainRoutes, AuthenticationRoutes];
    // return useRoutes([MainRoutes,AuthenticationRoutes]);
  }
  console.log(routesList);
  return useRoutes(routesList);
  // else{
  //   return useRoutes([LoginRoutes]);
  // }
};
const mapDispatchToProps = {
  loginUserId: loginUser
};
const mapStateToProps = (state) => {
  return {
    experimentList: state.skanRateCardStoreReducer,
    email: state.userReducer.userEmail,
    user_role: state.userReducer.fetchedUserRoleType
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeRoutes);
// export default function ThemeRoutes() {

// }
