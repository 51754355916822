import { LOGIN } from './skanRateCardStoreActionTypes';
const initialState = {
  login: false
};
const skanRateCardStoreReducer = (state = initialState, action) => {
  //console.log(initialState)
  //console.log(action.type,"<--login--->", action.payload)
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        login: action.payload
      };
    }
    default:
      return state;
  }
};
export default skanRateCardStoreReducer;
