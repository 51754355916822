export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SET_SELECTED_CAMPAIGNS = 'SET_SELECTED_CAMPAIGNS';
export const SET_SEARCH_DATES = 'SET_SEARCH_DATES';
export const SET_ISSKAN = 'SET_ISSKAN';
export const SET_DOLLAR = 'SET_DOLLAR';
export const FETCH_SELECTED_CAMPAIGNS_DATA = 'FETCH_SELECTED_CAMPAIGNS_DATA';
export const FETCH_FILTERED_DATA_FOR_PERFORMANCE = 'FETCH_FILTERED_DATA_FOR_PERFORMANCE';
export const FETCH_DATA_FOR_REPORTING = 'FETCH_DATA_FOR_REPORTING';
export const FETCH_DATA_FOR_REPORTING_SKAN = 'FETCH_DATA_FOR_REPORTING_SKAN';
export const FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATED = 'FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATED';
export const FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATEDV1 = 'FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATEDV1';
export const SET_STATIC_WIDZARDS_DATA = 'SET_STATIC_WIDZARDS_DATA';
export const FETCH_PERFORMANCE_REQUEST = 'FETCH_PERFORMANCE_REQUEST';
export const FETCH_WIDZARD_REQUEST = 'FETCH_WIDZARD_REQUEST';
export const SET_STATIC_WIDZARDS_DATA_SKAN = 'SET_STATIC_WIDZARDS_DATA_SKAN';
export const FETCH_REPORTING_REQUEST = 'FETCH_REPORTING_REQUEST';
export const FETCH_PERFORMANCE_REQUEST_ERROR = 'FETCH_PERFORMANCE_REQUEST_ERROR';
export const APPLY_FILTER = 'APPLY_FILTER';
export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_REQUEST_ERROR = 'FETCH_EVENT_REQUEST_ERROR';
export const FETCHED_EVENT_DATA = 'FETCHED_EVENT_DATA';
export const FETCH_ALL_DASHBORD_DATA = 'FETCH_ALL_DASHBORD_DATA';
export const RESET_VIEW_DATA = 'RESET_VIEW_DATA';
export const FETCH_WIDZARD_REQUEST_ERROR = 'FETCH_WIDZARD_REQUEST_ERROR';
export const FETCH_REPORTING_REQUEST_ERROR = 'FETCH_REPORTING_REQUEST_ERROR';
