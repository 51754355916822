import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent } from '@mui/material';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import MainCard from 'ui-component/cards/MainCard';
import LoaderCircular from 'ui-component/LoaderCircular';
import { gridSpacing } from 'store/constant';
import './styleSearch.scss';

const MediaCostIap = ({ performanceData, performanceLoading, campaign_list, error, isDollar }) => {
  const [showChart, setShowChart] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);

  // console.log("Performance Dat===>",performanceData)

  const [series, setSeries] = useState([
    {
      name: 'Media Cost',
      data: []
    }
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']
      }
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: []
    }
  });

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']
        }
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            //console.log(value)
            //return value/1000 + "K";
            return '$' + formatNumberAbbreviated(value);
          }
        }
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="custom-tooltip">' +
            '<span>Media Cost:</span>' +
            '<span>$ ' +
            formatNumberAbbreviated(series[0][dataPointIndex]) +
            '</span>' +
            '</div>'
          );
        }
      }
    },
    series: [
      {
        name: 'Media Cost',
        data: []
      }
    ]
  });

  // console.log("chartData Series ===>", JSON.stringify(chartData.series, null,3));

  const customDateSort = (a, b) => new Date(a.date) - new Date(b.date);
  function formatNumberAbbreviated(number) {
    if (number == 0) {
      return 0;
    }
    if (Number.isNaN(number)) {
      return null;
    }
    if (number < 1000) {
      return number;
    }
    const suffixes = ['', 'K', 'M', 'B'];

    const formatNumber = (num) => {
      const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places
      return roundedNum.toString().replace(/\.0+$/, ''); // Remove trailing zeros after decimal point
    };

    const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
    const abbreviatedNumber = number / Math.pow(10, suffixIndex * 3);

    return `${formatNumber(abbreviatedNumber)}${suffixes[suffixIndex]}`;
  }

  // useEffect(() => {
  //   if (performanceData.length !== 0 && performanceLoading === false) {
  //     //console.log('Processing new performance data...');

  //     // Extract unique dates
  //     let matricksData = [...new Set(performanceData.map((entry) => entry.date))];

  //     // console.log("matricksData ====>", JSON.stringify(matricksData, null, 2));

  //     // Group data by date and calculate total media cost
  //     const groupedData = performanceData.reduce((result, entry) => {
  //       const date = entry.date;

  //       if (!result[date]) {
  //         result[date] = {
  //           date: date,
  //           total_media_cost: 0
  //         };
  //       }
  //       result[date].total_media_cost += entry.media_cost;
  //       //   if (isDollar == false) {
  //       //     result[date].total_media_cost += entry.media_cost_local_currency;
  //       //   } else {
  //       //     result[date].total_media_cost += entry.media_cost;
  //       //   }
  //       // result[date].total_media_cost += entry.media_cost;
  //       result[date].total_media_cost = Math.round(result[date].total_media_cost, 2);
  //       return result;
  //     }, {});
  //     // Convert grouped data to array and sort by date
  //     const resultArray = Object.values(groupedData);
  //     let sortedData = resultArray.sort(customDateSort);

  //     // Extract dates and media costs
  //     let dates = [...new Set(sortedData.map((entry) => entry.date))];
  //     let mediacost = sortedData.map((entry) => entry.total_media_cost);

  //     // Ensure at least two data points
  //     if (dates.length === 1) {
  //       dates.unshift(dates[0]);
  //     }
  //     if (mediacost.length === 1) {
  //       mediacost = [0, ...mediacost];
  //     }

  //     // console.log('Updated ChartData ===> Dates:', dates);
  //     // console.log('Updated ChartData ===> Media Costs:', mediacost);

  //     setChartData((prevChartData) => {
  //       const newChartData = {
  //         ...prevChartData,
  //         options: {
  //           ...prevChartData.options,
  //           xaxis: {
  //             ...prevChartData.options.xaxis,
  //             categories: dates
  //           },
  //           yaxis: {
  //             ...prevChartData.options.yaxis,
  //             labels: {
  //               formatter: function (value) {
  //                 //  console.log("isDollar>>>",localIsDollar)
  //                 if (isDollar == false) {
  //                   return '¥ ' + formatNumberAbbreviated(value);
  //                 } else {
  //                   return '$ ' + formatNumberAbbreviated(value);
  //                 }
  //               }
  //             }
  //           }
  //         },
  //         series: [
  //           {
  //             ...prevChartData.series[0],
  //             data: mediacost
  //           }
  //         ]
  //       };
  //       // console.log("New chartData ===>", JSON.stringify(newChartData, null, 2));
  //       return newChartData;
  //     });
  //     setShowChart(true);
  //     //force update chartData - handle Media Cost Ghost error
  //     setForceUpdate((prev) => !prev);
  //   }
  // }, [performanceData, performanceLoading, isDollar]);
  // console.log(chartData,performanceLoading);
  // console.log('Processing new performance data...');
  useEffect(() => {
    if (performanceData.length !== 0 && performanceLoading === false) {
      // Extract unique dates
      let matricksData = [...new Set(performanceData.map((entry) => entry.date))];

      // Group data by date and calculate total media cost
      const groupedData = performanceData.reduce((result, entry) => {
        const date = entry.date;

        if (!result[date]) {
          result[date] = {
            date: date,
            total_media_cost: 0
          };
        }
        result[date].total_media_cost += isDollar ? entry.media_cost : entry.media_cost_local_currency;
        result[date].total_media_cost = Math.round(result[date].total_media_cost, 2);
        return result;
      }, {});

      // Convert grouped data to array and sort by date
      const resultArray = Object.values(groupedData);
      let sortedData = resultArray.sort(customDateSort);

      // Extract dates and media costs
      let dates = [...new Set(sortedData.map((entry) => entry.date))];
      let mediacost = sortedData.map((entry) => entry.total_media_cost);

      // Ensure at least two data points
      if (dates.length === 1) {
        dates = [dates[0]];  // Just keep the single date
        mediacost = [mediacost[0]];  // Only show the single media cost
      }

      // Calculate buffer for Y-axis
      const yMin = Math.max(0, Math.min(...mediacost) - 100);
      const yMax = Math.max(...mediacost) + 100;

      // console.log('Updated ChartData ===> Dates:', dates);
      // console.log('Updated ChartData ===> Media Costs:', mediacost);

      setChartData((prevChartData) => {
        const newChartData = {
          ...prevChartData,
          options: {
            ...prevChartData.options,
            xaxis: {
              ...prevChartData.options.xaxis,
              categories: dates
            },
            yaxis: {
              ...prevChartData.options.yaxis,
              min: yMin,   // Set minimum Y value
              max: yMax,   // Set maximum Y value
              labels: {
                formatter: function (value) {
                  return isDollar ? '$ ' + formatNumberAbbreviated(value) : '¥ ' + formatNumberAbbreviated(value);
                }
              }
            },
            // Update tooltip to show proper value for a single data point
            tooltip: {
              enabled: true,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  '<div class="custom-tooltip">' +
                  '<span>Media Cost:</span>' +
                  '<span>' + (isDollar ? ' $' : ' ¥') +
                  formatNumberAbbreviated(series[0][dataPointIndex]) +
                  '</span>' +
                  '</div>'
                );
              }
            },
            // Update markers for the single data point
            markers: {
              size: dates.length === 1 ? 6 : 0,  // Make the point bigger when only one date
              colors: ['#FF4560'],  // Example color
              strokeColor: '#00FF00',
              strokeWidth: 3
            }
          },
          series: [
            {
              ...prevChartData.series[0],
              data: mediacost
            }
          ]
        };
        return newChartData;
      });
      setShowChart(true);
      // Force update chartData to handle Media Cost Ghost error
      setForceUpdate((prev) => !prev);
    }
  }, [performanceData, performanceLoading, isDollar]);

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} lg={12}>
              <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h3">Media Cost vs Campaign</Typography>
                </Grid>
                <Grid item xs={12}>
                  {performanceLoading == true ? (
                    <div style={{ height: '200px', width: '100%' }}>
                      {' '}
                      <LoaderCircular show={true} />
                    </div>
                  ) : performanceData.length == 0 ? (
                    <div className="topPerformerDataContainer">
                      <Typography style={{ textAlign: 'center' }}>
                        {error.length != 0 ? (
                          'No Data Found'
                        ) : (
                          <span>
                            Please click <b>Apply Filter</b> to view data
                          </span>
                        )}
                      </Typography>
                    </div>
                  ) : performanceLoading == false && showChart == true ? (
                    <Chart
                      key={forceUpdate} // Force re-render by changing key
                      options={chartData.options}
                      series={chartData.series}
                      type="line"
                    />
                  ) : (
                    ''
                  )}
                  {/* {showChart!=true?
                              <div style={{height:'200px',width:'100%'}}> 
                                <LoaderCircular show={true}/>
                              </div>:<Chart options={chartData.options} series={chartData.series} type="line" />} */}
                  {/* {showChart==true?<Chart options={chartData.options} series={chartData.series} type="bar" />:''} */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};
const mapStateToProps = (state) => {
  return {
    performanceData: state.IAPReducer.graphData,
    dataFetched: state.campaignReducer.dataFetched,
    campaign_list: state.advertiserReducer.selected_campaigns,
    performanceLoading: state.IAPReducer.loading,
    error: state.campaignReducer.error,
    isDollar: state.campaignReducer.isDollar,
    timeZone: state.advertiserReducer.timeZone
  };
};
export default connect(mapStateToProps, null)(MediaCostIap);
