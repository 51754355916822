import axios from 'axios';
import {
  FETCH_IAP_DATA,
  FETCH_IAP_REQUEST,
  FETCH_IAP_RESET,
  FETCH_IAP_REQUEST_ERROR,
  FETCH_IAP_GRAPH_DATA,
  SET_STATIC_WIDZARDS_DATA_IAP
} from './iapTypes';
import BASE_URL from '../config';

axios.defaults.timeout = 120000;
const timeout = 120000;
export const fetchIAPDataRequest = (msg) => {
  return {
    type: FETCH_IAP_REQUEST,
    payload: [msg]
  };
};

export const resetIAPData = () => {
  return {
    type: FETCH_IAP_RESET,
    payload: []
  };
};

export const fetchIAPData = () => (dispatch, getState) => {
  dispatch(fetchIAPDataRequest('loading'));
  axios({
    method: 'post',
    url: `${BASE_URL}/getIAPReport`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      advertiser_id: localStorage.getItem('advertiserId'), //"7cfcc2e0c111419492a2010492e8c9df",//localStorage.getItem("advertiserId"),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: 'JST' //getState().advertiserReducer.timeZone,
    },
    timeout: timeout
  })
    .then((response) => {
      // console.log(response.data);
      if (response.data.length > 0) {
        //console.log(response.data[0].overview);
        dispatch(fetchedIAPReportDetails(response.data[0].overview));
        dispatch(fetchIAPGraphsData(response.data[0].charts));
        dispatch(fetchedWidzardDataIap(response.data[0].widgets));
      } else {
        return dispatch(fetchIAPRequestError('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchIAPRequestError('Not able to fetch data. Please try again'));
      //return Promise.reject(error)
    });
};
export const fetchedIAPReportDetails = (data) => {
  // Call the function with your JSON data
  // const detectedKeys = detectKeys(data);
  ////console.log("detectedKeys",detectedKeys)
  //console.log(data);
  const iapData = data.map((item, id = index) => ({ id, ...item }));
  //console.log('iapData', iapData);
  return {
    type: FETCH_IAP_DATA,
    payload: iapData
  };
};
export const fetchIAPRequestError = (error) => {
  return {
    type: FETCH_IAP_REQUEST_ERROR,
    payload: error
  };
};

// Widget and bar graph function in current use for Bar
export const fetchIAPGraphsData = (fetchedData) => {
  //console.log('fetchCampaignGraphsDataPulled');

  ////console.log("result",result)
  return {
    type: FETCH_IAP_GRAPH_DATA,
    payload: fetchedData
  };
};
export const fetchedWidzardDataIap = (data) => {
  //console.log(data[0]);
  // let timeZone=getState().advertiserReducer.timeZone;
  // if(timeZone!="JST"){
  //   data[0].media_cost_local_currency=0
  // }
  // console.log(data[0]);
  return {
    type: SET_STATIC_WIDZARDS_DATA_IAP,
    payload: data[0]
  };
};
