import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';

//import reset functions
import { resetScreenData } from '../../../redux/campaign/campaignActions.js';
import { resetReportData } from '../../../redux/reporting/reportingActions';
import { resetCreativeData } from '../../../redux/creative/creativeActions.js';
import { resetWowData } from '../../../redux/wow/wowActions.js'
import { resetIAPData } from '../../../redux/iap/iapActions.js';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectUser = () => {
    console.log('inside it');

    navigate(`advertisers`);
    dispatch(resetScreenData());
    dispatch(resetReportData());
    dispatch(resetCreativeData());
    dispatch(resetWowData());
    dispatch(resetIAPData());
    //  dispatch({ type: SET_SIDE_MENU, showSideMenu: false });
    //  localStorage.setItem('showSideMenu', false);
    //  localStorage.setItem('advertiserId', data.id);
    //  localStorage.setItem('sfId',data.sfId)
  };
  return (
    <>
      <ButtonBase
        disableRipple
        onClick={() => {
          dispatch({ type: MENU_OPEN, id: defaultId });
          redirectUser();
        }}
      >
        <Logo />
        <br />
      </ButtonBase>
    </>
  );
};

export default LogoSection;
