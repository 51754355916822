import {
  FETCH_BUNDLE_REPORTING_REQUEST,
  FETCH_BUNDLE_REPORTING_DATA,
  FETCH_BUNDLE_REPORTING_ERROR,
  RESET_REPORT_VIEW_DATA
} from './reportingTypes';

const initialState = {
  loading: false,
  reportingData: [],
  bundleReportLoading: false,
  error: ''
};
const reportingReducer = (state = initialState, action) => {
  //console.log(action.type);
  switch (action.type) {
    case FETCH_BUNDLE_REPORTING_REQUEST:
      return {
        ...state,
        loading: true,
        bundleReportLoading: true,
        error: ''
      };
    case FETCH_BUNDLE_REPORTING_ERROR:
      return {
        ...state,
        loading: false,
        reportingData: [],
        bundleReportLoading: false,
        error_msg: 'Sorry no data found',
        error: action.payload
      };
    case FETCH_BUNDLE_REPORTING_DATA: {
      return {
        ...state,
        loading: false,
        bundleReportLoading: false,
        reportingData: action.payload,
        error: ''
      };
    }
    case RESET_REPORT_VIEW_DATA: {
      return {
        ...state,
        reportingData: [],
        error: ''
      };
    }
    default:
      return state;
  }
};
export default reportingReducer;
