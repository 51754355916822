import React, { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { connect, useDispatch } from 'react-redux';
import { Typography, Grid, Paper } from '@mui/material';
import IapDataView from 'components/iap/IapDataView';
import IapAnalysisReport from 'components/iap/IapAnalysisReport';
import SearchBarIAP from 'components/Dashboard/SearchBarIAP';
import CampaignPieIap from 'components/Dashboard/CampaignPieIap';
import MediaCostIap from 'components/Dashboard/MediaCostIap';
import LoaderCustom from 'ui-component/LoaderCustom';
import { fetchIAPData } from '../redux/iap/iapActions';
import WidgetContainerIap from 'components/Dashboard/WidgetContainerIap';
import { SET_SIDE_MENU } from 'store/actions';
const IAPPage = ({ fetchIAPData, advertiserList }) => {
  useEffect(() => {
    //fetchIAPData()
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem('showSideMenu', true);
    dispatch({ type: SET_SIDE_MENU, showSideMenu: true });
  }, []);
  return (
    <>
      {' '}
      {advertiserList.length == 0 ? (
        <LoaderCustom />
      ) : (
        <>
          <Paper elevation spacing={gridSpacing} className="stickyBox">
            <SearchBarIAP />
          </Paper>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={6} xs={12}>
                  {/* <SearchBar/> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <WidgetContainerIap />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <div style={{ height: '100%' }}>
                    <IapAnalysisReport />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <CampaignPieIap />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <MediaCostIap />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <IapDataView />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.creativeReducer.creativeLoading,
    advertiserList: state.advertiserReducer.advertiser_list,
    isSkan: state.campaignReducer.isSkan
  };
};
const mapDispatchToProps = {
  fetchIAPData: fetchIAPData
};
export default connect(mapStateToProps, mapDispatchToProps)(IAPPage);
