export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const FETCH_IAM_USER_LIST = 'FETCH_IAM_USER_LIST';
export const FETCH_IAM_FAILURE = 'FETCH_IAM_FAILURE';
export const USER_IAM_ONBOARD_SUCCESS = 'USER_IAM_ONBOARD_SUCCESS';
export const USER_IAM_ONBOARD_FAILURE = 'USER_IAM_ONBOARD_FAILURE';
export const RESET_ADD_USER_DATA = 'RESET_ADD_USER_DATA';
export const CHECK_IF_USER_EXIST = 'CHECK_IF_USER_EXIST';
export const CHECK_IF_USER_EXIST_SUCCESS = 'CHECK_IF_USER_EXIST_SUCCESS';
export const CHECK_IF_USER_EXIST_FAILURE = 'CHECK_IF_USER_EXIST_FAILURE';
export const ADD_USER_DETAILS_TO_DASHBOARD = 'ADD_USER_DETAILS_TO_DASHBOARD';
export const FETCH_ALL_DASHBOARD_USER_LIST = 'FETCH_ALL_DASHBOARD_USER_LIST';
export const FETCHED_ADVERTISERS_FOR_USER_BASED_ON_EMAIL = 'FETCHED_ADVERTISERS_FOR_USER_BASED_ON_EMAIL'; // FETCH user data based on email
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCHED_USER_TYPE = 'FETCHED_USER_TYPE';
export const FETCH_USER_TYPE_ERROR = 'FETCH_USER_TYPE_ERROR';
export const UPDATE_USER_ADVERTISER_SETTINGS = 'UPDATE_USER_ADVERTISER_SETTINGS';
export const USER_DETAILS_STORED_SUCCESS = 'USER_DETAILS_STORED_SUCCESS';
export const USER_UI_SETTINGS_ON_TIMEZONE = 'USER_UI_SETTINGS_ON_TIMEZONE';
export const FETCH_USER_PLATFORMS = 'FETCH_USER_PLATFORMS';
