import React, { useState, useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Autocomplete, TextField, Box, Chip, Typography } from '@mui/material';
import LoaderCustom from 'ui-component/LoaderCustom';
import { SET_ADVERTISER, FETCH_REQUEST } from '../../redux/advertiser/advertiserTypes';

import {
  fetchAdvertiserList,
  setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser,
  fetchJSTCampaignsForAdvertiser,
  fetchUserRoleByEmailId
} from '../../redux/advertiser/advertiserActions';
import { SET_ISSKAN } from '../../redux/campaign/campaignTypes';
import { resetReportData } from '../../redux/reporting/reportingActions';
import { resetCreativeData } from '../../redux/creative/creativeActions';
import { fetchPerformanceRequestError, resetScreenData } from '../../redux/campaign/campaignActions';
import { fetchAdvertiserListForProvidedEmail } from '../../redux/user/userActions';
import { resetWowData } from "../../redux/wow/wowActions";
import { resetIAPData } from "../../redux/iap/iapActions";
import BASE_URL from "../../redux/config";
const AdvertisersFilter = ({
  fetchAdvertiserList,
  advertiserList,
  advertiser_guid,
  advertiser_name,
  campaign_list,
  fetchCampaignsForAdvertiser,
  loading,
  login,
  userEmail,
  isSkan,
  fetchSkanCampaignsForAdvertiser,
  advertiser_campaign_fetching,
  performanceLoading,
  widzardLoading,
  reportLoading,
  fetchPerformanceRequestError,
  resetScreenData,
  resetReportData,
  resetCreativeData,
  fetchAdvertiserListForProvidedEmail,
  timeZone,
  fetchJSTCampaignsForAdvertiser,
  fetchUserRoleByEmailId,
  resetWowData,
  resetIAPData
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(['']);
  const [isSkanPresent, setIsSkanPresent] = useState(isSkan);
  const [disableAdvertiserDropDown, setDisableAdvertiserDropDown] = React.useState(false);
  let advertiser_id = localStorage.getItem('advertiserId');
  //console.log(advertiser_id)
  const defaultProps = {
    options: advertiserList,
    getOptionLabel: (option) => option.title
  };
  //console.log('Time zone--->', timeZone);
  useEffect(() => {
    // Retrieve selected option from local storage on component mount
    const storedOption = localStorage.getItem('advertiserId');
    //timeZone = localStorage.getItem("timeZone");
    //console.log('isSkan', isSkan);
    //console.log('advertiserList', advertiserList);
    //fetchAdvertiserListForProvidedEmail(userEmail)
    if (storedOption && advertiserList.length > 0) {
      let selectedAdvertiserData = advertiserList.filter((option) => option.advertiser_guid == storedOption);
      //console.log(selectedAdvertiserData[0]);
      // localStorage.setItem('sfId',selectedAdvertiserData[0].sfId)
      setSelectedOption(selectedAdvertiserData[0]);
      let data = {
        advertiser_guid: selectedAdvertiserData[0].advertiser_guid,
        advertiser_name: selectedAdvertiserData[0].advertiser_name,
        sfId: selectedAdvertiserData[0].salesforce_account_id,
        timeZone: selectedAdvertiserData[0].timezone,
        startDate: selectedAdvertiserData[0].start_date,
        platform: selectedAdvertiserData[0].platform
      };
      timeZone = selectedAdvertiserData[0].timezone;
      //console.log(data);
      dispatch({ type: SET_ADVERTISER, payload: data });
      if (isSkan == true) {
        fetchSkanCampaignsForAdvertiser(selectedAdvertiserData[0].advertiser_guid, selectedAdvertiserData[0].salesforce_account_id);
      } else {
        // fetchCampaignsForAdvertiser(
        //   selectedAdvertiserData[0].advertiser_guid,
        //   selectedAdvertiserData[0].salesforce_account_id,
        // );
        if (timeZone == 'JST') {
          //console.log('inside JST Area');
          fetchJSTCampaignsForAdvertiser(selectedAdvertiserData[0].advertiser_guid, selectedAdvertiserData[0].salesforce_account_id);
        } else {
          fetchCampaignsForAdvertiser(selectedAdvertiserData[0].advertiser_guid, selectedAdvertiserData[0].salesforce_account_id);
        }
      }
    }
  }, [advertiserList, isSkan]);

  const handleInputChange = (event, value) => {
    if (value != null) {
      setSelectedOption(value);
      dispatch({ type: FETCH_REQUEST, payload: '' });
      fetchPerformanceRequestError('');

      const currentPath = window.location.pathname;  // Capture the current path (/dashboard, /creative, /publisher)

      if (isSkan == true) {
        fetchSkanCampaignsForAdvertiser(value.advertiser_guid, value.salesforce_account_id);
      } else {
        if (timeZone === 'JST') {
          fetchJSTCampaignsForAdvertiser(value.advertiser_guid, value.salesforce_account_id);
        } else {
          fetchCampaignsForAdvertiser(value.advertiser_guid, value.salesforce_account_id);
        }
      }

      localStorage.setItem('advertiserId', value.advertiser_guid);
      localStorage.setItem('sfId', value.salesforce_account_id);
      localStorage.setItem('timeZone', value.timezone);
      localStorage.setItem('startDate', value.start_date);
      localStorage.setItem('platform', value.platform);

      let data = {
        advertiser_guid: value.advertiser_guid,
        advertiser_name: value.advertiser_name,
        sfId: value.salesforce_account_id,
        timeZone: value.timezone,
        startDate: value.start_date,
        platform: value.platform
      };

      dispatch({ type: SET_ADVERTISER, payload: data });

      resetScreenData();
      resetReportData();
      resetCreativeData();
      resetWowData();
      resetIAPData();
      //let campaignDetailsForAdvertiser=campaign_list.filter(data=>data.advertiserID==advertiser_guid);
      // dispatch({ type: FETCH_CAMPAIGN_LIST_BY_ADVERTISER, payload:campaignDetailsForAdvertiser});
      //fetchCampaignsByAdvertiserGuid(value.advertiser_guid);

      const platformFromStorage = localStorage.getItem('platform');
      const activePlatform = value.platform || platformFromStorage;

      if (activePlatform) {
        if (activePlatform.includes('IAP') && !activePlatform.includes('DSP')) {
          window.location.href = `${BASE_URL}/iap`;
        } else if (activePlatform.includes('DSP') && !activePlatform.includes('IAP')) {
          if (currentPath.includes('/iap')) {
            window.location.href = `${BASE_URL}/dashboard`;  // force redirect
          } else {
            window.location.href = `${BASE_URL}${currentPath}`;  // Maintain the current path
          }
        } else if (activePlatform.includes('DSP') && activePlatform.includes('IAP')) {
          window.location.href = `${BASE_URL}${currentPath}`;  // Maintain the current path
        }
      }
    }
  };

  useEffect(() => {
    //console.log(advertiserList);
    if (login == true && advertiserList.length == 0) {
      fetchAdvertiserList(userEmail);
      fetchAdvertiserListForProvidedEmail(userEmail);
      fetchUserRoleByEmailId(userEmail);
    }
  }, [login, advertiserList]);
  useEffect(() => {
    //console.log(selectedOption)
  }, [selectedOption]);
  useEffect(() => {
    // console.log('campaign_list', campaign_list.length, isSkan, advertiser_campaign_fetching);
    // if(isSkan==false && campaign_list.length==0 && advertiser_campaign_fetching==false){
    //   console.log("Android no campaign")
    //   setIsSkanPresent(true);
    //   dispatch({ type: SET_ISSKAN, payload: true });
    //   // console.log(isSkan)
    // }
    // else if(isSkan==true && campaign_list.length==0 && advertiser_campaign_fetching==false){
    //   setIsSkanPresent(false);
    //   dispatch({ type: SET_ISSKAN, payload: false });
    //   // console.log(isSkan)
    //   console.log("Ios no campaign")
    // }
  }, [isSkan, advertiser_campaign_fetching]);

  useEffect(() => {
    // console.log(
    //   'advertiser_campaign_fetching',
    //   advertiser_campaign_fetching,
    //   'performanceLoading',
    //   performanceLoading,
    //   'widzardLoading',
    //   widzardLoading,
    //   'reportLoading',
    //   reportLoading
    // );
    // if(advertiser_campaign_fetching==true || performanceLoading==true || widzardLoading==true){
    //     setDisableAdvertiserDropDown(true)
    // }
    // else{
    //     setDisableAdvertiserDropDown(false)
    // }
  }, [advertiser_campaign_fetching, performanceLoading]);
  return (
    <>
      {/* <LoaderCustom show={loading}/> */}

      <Autocomplete
        value={selectedOption}
        disabled={disableAdvertiserDropDown}
        sx={{ zIndex: '999999' }}
        onChange={handleInputChange}
        options={advertiserList}
        renderOption={(props, option) => (
          <li {...props}>
            {option.advertiser_name}&nbsp;
            <Typography style={{ color: 'green' }}>{option.timezone}</Typography>
          </li>
        )}
        getOptionLabel={(option) => (option ? `${option.advertiser_name} (${option.timezone})` : 'Loading...')}
        renderInput={(params) => <TextField {...params} label="Select Advertiser" variant="outlined" />}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    advertiserList: state.advertiserReducer.advertiser_list,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
    advertiser_name: state.advertiserReducer.advertiser_name,
    campaign_list: state.advertiserReducer.campaign_list,
    login: state.userReducer.login,
    userEmail: state.userReducer.userEmail,
    isSkan: state.campaignReducer.isSkan,
    isSkanPresent: state.campaignReducer.isSkan,
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    timeZone: state.advertiserReducer.timeZone
  };
};
const mapDispatchToProps = {
  fetchAdvertiserList: fetchAdvertiserList,
  setSelectedAdvertiser: setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid: fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser: fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser: fetchSkanCampaignsForAdvertiser,
  fetchPerformanceRequestError: fetchPerformanceRequestError,
  resetScreenData: resetScreenData,
  resetReportData: resetReportData,
  resetCreativeData: resetCreativeData,
  fetchAdvertiserListForProvidedEmail: fetchAdvertiserListForProvidedEmail,
  fetchJSTCampaignsForAdvertiser: fetchJSTCampaignsForAdvertiser,
  fetchUserRoleByEmailId: fetchUserRoleByEmailId,
  resetWowData: resetWowData,
  resetIAPData: resetIAPData,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisersFilter);
