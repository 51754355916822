import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { styled, useTheme } from '@mui/material/styles';
import { connect, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  CssBaseline,
  AppBar,
  Input,
  Select,
  TextField,
  Autocomplete,
  Checkbox,
  Box,
  FormGroup,
  label,
  Chip
} from '@mui/material';
import { setSelectedCampaignList } from '../../redux/advertiser/advertiserActions';
//import reset functions
import { resetScreenData } from '../../redux/campaign/campaignActions';
import { resetReportData } from '../../redux/reporting/reportingActions';
import { resetCreativeData } from '../../redux/creative/creativeActions.js';
import { resetWowData } from '../../redux/wow/wowActions.js'
import { resetIAPData } from '../../redux/iap/iapActions.js';

const CampaignAutocompleteDropDown = ({
  campaign_list,
  campaign_list_rest,
  setSelectedCampaignList,
  advertiser_campaign_fetching,
  performanceLoading,
  widzardLoading,
  reportLoading,
  isSkan,
  campaign_list_error_msg,
  selected_campaign_list
}) => {
  const campaigns = campaign_list;
  const [value, setValue] = React.useState(campaigns); //React.useState(campaigns.slice(0, 2));
  const [selectAll, setSelectAll] = React.useState(false);
  const [campaign_guid_list, setCampaign_guid_list] = React.useState([]);
  const [disableCampaignDropDown, setDisableCampaignDropDown] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  let defaultSelections = []; //campaigns;//.slice(0, 2);
  const dispatch = useDispatch();

  //console.log('defaultSelections', defaultSelections);
  const renderTags = (value1, getTagProps) => {
    return (
      <>
        {value.slice(0, 2).map((option, index) => (
          <Chip {...getTagProps({ index })} key={index} label={option.campaign_name} onDelete={() => handleDelete(option)} />
        ))}
        {value.length > 2 && (
          <span>
            {'+'}
            {value.length - 2} more
          </span>
        )}
      </>
    );
  };
  const handleDelete = (deletedOption) => {
    //let campaignIds = value.map((value) => value.campaign_guid);
    const newValue = value.filter((option) => option.campaign_guid != deletedOption.campaign_guid);

    setValue(newValue);
    let campaignIds = newValue.map((value) => value.campaign_guid);
    //console.log('campaignIds', campaignIds);
    if (campaignIds.length == 0) {
      campaignIds = campaign_list.map((value) => value.campaign_guid);
      setCampaign_guid_list([]);
      setSelectedCampaignList([]);
      defaultSelections = [];
      // setCampaign_guid_list(campaignIds);
      // setSelectedCampaignList(campaignIds)
    } else {
      setCampaign_guid_list(campaignIds);
      setSelectedCampaignList(campaignIds);
    }
  };
  const setCampaigns = (newValue) => {
    let campaignIds = newValue.map((value) => value.campaign_guid);
    debouncedApiCall(campaignIds);
    if (campaignIds.length == 0) {
      campaignIds = campaign_list.map((value) => value.campaign_guid);

      // setCampaign_guid_list(campaignIds);
      // setSelectedCampaignList(campaignIds)
    } else {
      setCampaign_guid_list(campaignIds);
      setSelectedCampaignList(campaignIds);
    }
  };
  const debouncedApiCall = debounce((campaignIds) => {
    // Perform your API call here with the selectedCampaigns
    //console.log("API call with selected campaigns:", campaignIds);
    if (campaignIds.length == 0) {
      campaignIds = campaign_list.map((value) => value.campaign_guid);
      setCampaign_guid_list([]);
      setSelectedCampaignList([]);
      defaultSelections = [];
      // setCampaign_guid_list(campaignIds);
      // setSelectedCampaignList(campaignIds)
    } else {
      setCampaign_guid_list(campaignIds);
      setSelectedCampaignList(campaignIds);
      defaultSelections = campaignIds;
    }
  }, 100); // Adjust the debounce delay as needed

  useEffect(() => {
    // console.log("campaign_list", campaign_list,value);
    // console.log("defaultSelections",defaultSelections)
    if (campaign_list.length != 0 && value.length == 0) {
      let campaignIds = campaign_list.map((value) => value.campaign_guid);
      // setCampaign_guid_list(campaignIds)
      // setValue(campaign_list.slice(0, 2));
      setValue(campaign_list);
    }

    if (campaign_list_rest == true) {
      //console.log("campaign_list",campaign_list)

      setValue([]);
    }
  }, [campaign_list, campaign_list_rest, isSkan]);
  useEffect(() => {
    // console.log(
    //   'advertiser_campaign_fetching',
    //   advertiser_campaign_fetching,
    //   'performanceLoading',
    //   performanceLoading,
    //   'widzardLoading',
    //   widzardLoading,
    //   'reportLoading',
    //   reportLoading
    // );
    // if(advertiser_campaign_fetching==true || performanceLoading==true || widzardLoading==true){
    //   setDisableCampaignDropDown(true)
    // }
    // else{
    //   setDisableCampaignDropDown(false)
    // }
  }, [advertiser_campaign_fetching, performanceLoading]);
  useEffect(() => {
    // console.log('value', value);
    // console.log('selected_campaign_list', selected_campaign_list);
  }, [selected_campaign_list]);

  return (
    <>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={campaigns}
        defaultValue={campaigns}
        disableCloseOnSelect
        value={value}
        disabled={disableCampaignDropDown}
        onChange={(event, newValue) => {
          dispatch(resetScreenData());
          dispatch(resetReportData());
          dispatch(resetCreativeData());
          dispatch(resetWowData());
          dispatch(resetIAPData());
          setCampaigns(newValue);
          setValue(newValue);
        }}
        getOptionLabel={(option) => option.campaign_name}
        style={{ width: '100%', overflowY: 'auto', padding: '5px' }}
        renderInput={(params) => <TextField {...params} label="Select Campaigns" variant="outlined" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option.campaign_name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.campaign_name === value.campaign_name}
        renderTags={renderTags}
      />
      <Typography variant="h5" sx={{ color: 'red' }}>
        {campaign_list_error_msg}
      </Typography>
      {value.length == 0 && campaign_list_error_msg == '' ? (
        <Typography variant="h5" sx={{ color: 'red' }}>
          Please select some campaign from the above dropdown
        </Typography>
      ) : (
        ''
      )}
    </>
  );
};

const mapDispatchToProps = {
  setSelectedCampaignList: setSelectedCampaignList
};

const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    campaign_list: state.advertiserReducer.campaign_list,
    campaign_list_rest: state.advertiserReducer.campaign_list_rest,
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    isSkan: state.campaignReducer.isSkan,
    campaign_list_error_msg: state.advertiserReducer.campaign_list_error_msg,
    selected_campaign_list: state.advertiserReducer.selected_campaigns
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CampaignAutocompleteDropDown);
