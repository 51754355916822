import React, { useState, useEffect } from 'react';
// material-ui
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Grid, TextField, Select, MenuItem } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router-dom';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { SET_SIDE_MENU } from 'store/actions';
import AdvertiserCard from '../views/dashboard/Default/AdvertiserCard';
import { fetchAdvertiserList, filterAdvertiserData, fetchUserRoleByEmailId } from '../redux/advertiser/advertiserActions';
import { fetchUserDetails, fetchAdvertiserListForProvidedEmail } from '../redux/user/userActions';
import LoaderCircular from 'ui-component/LoaderCircular';
import LoaderCustom from 'ui-component/LoaderCustom';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// ==============================|| SAMPLE PAGE ||============================== //

const Advertisers = ({
  fetchAdvertiserList,
  advertiserList,
  login,
  userEmail,
  user_not_onboarded,
  filterAdvertiserData,
  filtered_advertiser_list,
  fetchUserRoleByEmailId,
  user_role
}) => {
  ReactGA.send({
    hitType: 'pageview',
    page: '/advertisers',
    title: 'Advertisers'
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [searchAdvertiser, setSearchAdvertiser] = useState('');
  const [timeZone, setTimeZone] = useState('ALL');
  const [filteredData, setFilteredData] = useState([]);
  // let filteredData = advertiserList.filter(item =>
  //   item.name.toLowerCase().includes(searchAdvertiser.toLowerCase())
  // );
  //console.log('user_role', user_role);
  useEffect(() => {
    //console.log(login)

    if (login == true && userEmail != '') {
      setLoader(true);
      fetchAdvertiserList(userEmail);
      fetchUserRoleByEmailId(userEmail);
    }

    dispatch({ type: SET_SIDE_MENU, showSideMenu: false });
    localStorage.setItem('showSideMenu', false);
  }, [login]);
  useEffect(() => {
    // console.log(
    //   "inside advertiser page--->",
    //   advertiserList,
    //   user_not_onboarded,
    // );

    if (advertiserList.length > 0) {
      setLoader(false);
      setFilteredData(advertiserList);
    }
    if (user_not_onboarded == true) {
      navigate('/', { replace: true });
    }

    fetchUserDetails();
  }, [advertiserList, user_not_onboarded]);
  const handleSearch = (event) => {
    setSearchAdvertiser(event.target.value);
    //console.log('timeZone', timeZone);
    if (timeZone == 'ALL') {
      setFilteredData(advertiserList.filter((item) => item.advertiser_name.toLowerCase().includes(event.target.value.toLowerCase())));
      filterAdvertiserData(filtered_advertiser_list, event.target.value);
    } else {
      setFilteredData(
        advertiserList.filter(
          (item) => item.advertiser_name.toLowerCase().includes(event.target.value.toLowerCase()) && item.timezone === timeZone
        )
      );
      filterAdvertiserData(filtered_advertiser_list, event.target.value);
    }
  };
  const timeZoneChange = (event) => {
    setTimeZone(event.target.value);
    //console.log(event.target.value);
    if (event.target.value == 'ALL') {
      let newList = advertiserList.filter((item) => item.advertiser_name.toLowerCase().includes(searchAdvertiser.toLowerCase()));

      setFilteredData(newList);
      //console.log("inside UTC")
      filterAdvertiserData(newList, searchAdvertiser);
    }
    if (event.target.value == 'UTC') {
      let newList = advertiserList.filter(
        (item) => item.timezone === 'UTC' && item.advertiser_name.toLowerCase().includes(searchAdvertiser.toLowerCase())
      );

      setFilteredData(newList);
      //console.log("inside UTC")
      filterAdvertiserData(newList, searchAdvertiser);
    }
    if (event.target.value == 'JST') {
      // let newList=advertiserList.filter(item =>
      //   item.timezone==='JST' && item.advertiser_name.toLowerCase().includes(searchAdvertiser.toLowerCase())
      // )
      let newList = advertiserList.filter(
        (item) => item.timezone === 'JST' && item.advertiser_name.toLowerCase().includes(searchAdvertiser.toLowerCase())
      );

      //console.log(newList)

      setFilteredData(newList);
      filterAdvertiserData(newList, searchAdvertiser);
      //console.log("inside JST")
    }
    // else{
    //   console.log("inside others")
    //   setFilteredData(advertiserList.filter(item =>
    //     item.advertiser_name.toLowerCase().includes(searchAdvertiser.toLowerCase())
    //   ))
    // }
  };

  //console.log("filteredData",filteredData);
  return (
    <>
      {/* Error message if access is denied */}
      {error === 'AccessDenied' && (
        <Typography color="error" variant="h6">
          Access denied! User doesn’t exist. Please get yourself invited.
        </Typography>
      )}

      {advertiserList.length == 0 ? (
        <LoaderCustom />
      ) : (
        <>
          {' '}
          <MainCard title="Advertiser List">
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={6}>
                    <TextField
                      label="Search Advertiser Name"
                      variant="outlined"
                      style={{ width: '100%' }}
                      id="appId"
                      value={searchAdvertiser}
                      placeholder="Enter Advertiser Name"
                      onChange={handleSearch}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    {user_role == 'admin' ? (
                      <Select label="Time Zone" value={timeZone} onChange={timeZoneChange} placeholder="Enter Advertiser Name">
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="UTC">UTC</MenuItem>
                        <MenuItem value="JST">JST</MenuItem>
                      </Select>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  {filteredData.length ? (
                    filteredData.map((advertiser, id) => (
                      <Grid item xs={4} sm={4} md={4} lg={3} xl={4} key={id}>
                        <AdvertiserCard data={advertiser} />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={4}>
                      <b>No advertiser Mapped</b>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    advertiserList: state.advertiserReducer.advertiser_list,
    login: state.userReducer.login,
    userEmail: state.userReducer.userEmail,
    user_not_onboarded: state.advertiserReducer.user_not_onboarded,
    filtered_advertiser_list: state.advertiserReducer.filtered_advertiser_list,
    user_role: state.advertiserReducer.user_type
  };
};
const mapDispatchToProps = {
  fetchAdvertiserList: fetchAdvertiserList,
  fetchUserDetails: fetchUserDetails,
  filterAdvertiserData: filterAdvertiserData,
  fetchAdvertiserListForProvidedEmail: fetchAdvertiserListForProvidedEmail,
  fetchUserRoleByEmailId: fetchUserRoleByEmailId
};
export default connect(mapStateToProps, mapDispatchToProps)(Advertisers);
