import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Checkbox, TextField, Typography, Chip, Popover, Box } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { fetchWowAdvertiserQuarters } from '../../redux/wow/wowActions';
import { SET_SELECTED_WOW_QUARTERS } from '../../redux/wow/wowTypes';
import { MicNone } from '@mui/icons-material';

const WowQuarterDropDown = ({
  fetchWowAdvertiserQuarters,
  advertiser_quarters,
  advertiser_guid,
  selected_wow_quarters,
  isSkanPresent,
  campaign_list
}) => {
  const [wowQuarterss, setWowQuarterss] = useState([]);
  const [wowQuartersError, setWowQuartersError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    fetchWowAdvertiserQuarters();
  }, [advertiser_guid, isSkanPresent]);

  const setWowQuarters = (data) => {
    dispatch({
      type: SET_SELECTED_WOW_QUARTERS,
      payload: {
        selected_wow_quarter: data
      }
    });
  };

  useEffect(() => {
    if (campaign_list.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [campaign_list]);

  useEffect(() => {
    if (advertiser_quarters.length > 0 && wowQuarterss.length === 0) {
      setWowQuarterss(advertiser_quarters);
    }
  }, [advertiser_quarters]);

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setWowQuartersError("Please select at least one Quarter.");
    } else {
      setWowQuartersError("");
    }
    setWowQuarterss(newValue);
    setWowQuarters(newValue);
  };

  return (
    <div ref={dropdownRef}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={advertiser_quarters}
        disableCloseOnSelect
        value={wowQuarterss}
        onChange={handleChange}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option, { selected }) => (
          <li {...props}
            style={{
              padding: 0,
              margin: 0
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '6px 16px',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
              }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '32px'  // Fixed width for checkbox container
              }}>
                <Checkbox
                  checked={selected}
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    padding: '4px',
                    '& .MuiSvgIcon-root': {
                      fontSize: 20,
                      color: selected ? 'primary.main' : 'grey.500'
                    }
                  }}
                />
              </Box>
              <Typography
                component="span"
                sx={{
                  flex: 1,
                  lineHeight: '1.5',
                  paddingLeft: '4px'
                }}
              >
                {option}
              </Typography>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Quarter"
            variant="outlined"
            error={!!wowQuartersError}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '8px'
                }
              }
            }}
          />
        )}
        renderTags={(value, getTagProps) => (
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            // gap: 0.5,
            alignItems: 'center'
          }}>
            {value.slice(0, 2).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={index}
                label={option}
                onDelete={(e) => {
                  e.stopPropagation();
                  const newValue = value.filter(v => v !== option);
                  handleChange(null, newValue);
                }}
                sx={{
                  height: '30px',
                  '& .MuiChip-label': {
                    padding: '0 8px',
                    maxWidth: 'none' //remove text truncate
                  }
                }}
              />
            ))}
            {value.length > 2 && (
              <Chip
                label={`+${value.length - 2} more`}
                onClick={handleOpenPopover}
                sx={{
                  cursor: 'pointer',
                  height: '30px',
                  '& .MuiChip-label': {
                    padding: '0 8px'
                  }
                }}
              />
            )}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              onClick={(e) => e.stopPropagation()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={{
                sx: {
                  maxHeight: 200,
                  overflow: 'auto',
                  boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
                }
              }}
            >
              <Box sx={{ p: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {value.slice(2).map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    onDelete={() => {
                      const newValue = value.filter(v => v !== option);
                      handleChange(null, newValue);
                    }}
                    sx={{
                      margin: '2px',
                      height: '24px',
                      '& .MuiChip-label': {
                        padding: '0 8px'
                      }
                    }}
                  />
                ))}
              </Box>
            </Popover>
          </Box>
        )}
        ListboxProps={{
          sx: {
            maxHeight: 200,
            '& .MuiAutocomplete-option': {
              padding: 0
            }
          }
        }}
        sx={{
          width: '100%',
          '& .MuiAutocomplete-tag': {
            margin: '2px'
          }
        }}
      />
      {wowQuartersError && (
        <Typography
          color="error"
          variant="caption"
          sx={{
            display: 'block',
            marginTop: 1
          }}
        >
          {wowQuartersError}
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.wowReducer.loading,
    advertiser_quarters: state.wowReducer.advertiser_quarters,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
    selected_wow_quarters: state.wowReducer.selected_advertiser_wow_quarters,
    isSkanPresent: state.campaignReducer.isSkan,
    campaign_list: state.advertiserReducer.campaign_list
  };
};

const mapDispatchToProps = {
  fetchWowAdvertiserQuarters: fetchWowAdvertiserQuarters
};

export default connect(mapStateToProps, mapDispatchToProps)(WowQuarterDropDown);