import React, { useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { connect } from 'react-redux';
import { Grid, Modal, Box, Typography, TextField, Button, Divider, Autocomplete, Checkbox, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { fetchAdvertiserList } from '../../redux/advertiser/advertiserActions';
import { sendIAMInvite, resetIAMFormData, checkUserAlreadyExist, onboardUserToDashboard } from '../../redux/user/userActions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
};
const AddUser = ({
  fetchAdvertiserList,
  advertiserList,
  sendIAMInvite,
  onboardedIAMStatus,
  onboardedToDashboard,
  resetIAMFormData,
  iamUserList,
  checkUserAlreadyExist,
  userLoginForDashboardAlreadyExist,
  onboardUserToDashboard
}) => {
  //console.log(advertiserList)
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    advertiserList: [],
    sfid: '',
    advertiserName: '',
    advertiserGuid: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    sfid: '',
    advertiserGuid: '',
    message: '',
    advertiserName: '',
    advertiserList: ''
  });
  const [selected_advertisers, setSelected_advertisers] = React.useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    resetIAMFormData();
  };
  useEffect(() => {
    fetchAdvertiserList();
  }, []);
  useEffect(() => {
    if (userLoginForDashboardAlreadyExist == true) {
      let prevError = errors.email;
      setErrors((prevErrors) => ({
        ...prevErrors,
        ['email']: prevError
      }));
    }
  }, [userLoginForDashboardAlreadyExist]);
  const renderTags = (value1, getTagProps) => {
    return (
      <>
        {value.map((option, index) => (
          <Chip {...getTagProps({ index })} key={index} label={option.name} onDelete={() => handleDelete(option)} />
        ))}
        {/* {value.length > 2 && (
              <span>
                {"+"}
                {value.length - 2} more
              </span>
            )} */}
      </>
    );
  };
  const handleDelete = (deletedOption) => {
    //console.log(deletedOption,value)
    const newValue = value.filter((option) => option !== deletedOption);
    setValue(newValue);
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  // Check user already IAM onboarded or not
  const checkEmailExist = (email) => {
   // console.log(email);
    // console.log(iamUserList)
    const emailExist = iamUserList.some((iamUser) => iamUser.email === email);
    // console.log(emailExist)
    return emailExist;
  };
  //Check whether user is already onboarded to Dashboard Backend
  const checkUserAlreadyExistOnDashboard = (email) => {
   // console.log('checkUserAlreadyExistOnDashboard', email);
    let status;
    if (email != '') {
      status = checkUserAlreadyExist(email);
    }
    return status;
  };
  const validateField = (name, value) => {
    let error = '';
    if (name === 'dropdown' && value.length === 0) {
      console.log('inside this');
      error = 'At least one option must be selected';
      //isValid = false;
    } else if (name === 'name' && value.trim() === '') {
      error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    } else if (name === 'advertiserGuid' && value.trim() === '') {
      error = `Advertiser Guid is required`;
    } else if (name === 'advertiserName' && value.trim() === '') {
      error = `Advertiser Name is required`;
    } else if (name === 'email' && !validateEmail(value)) {
      error = 'Invalid email address';
    } else if (name === 'email' && checkEmailExist(value)) {
      error = 'Email Already Exist ON IAM';
    } else if (name === 'sfid' && value.trim() === '') {
      error = `SF Id is required`;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
    if (name === 'email' && validateEmail(value) == true) {
      console.log('valid email');
      let userExist = checkUserAlreadyExistOnDashboard(value);
      console.log(userExist);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    validateField(name, value);
  };

  const handleAutocompleteChange = (event, value) => {
    setValue(value);
    const selectedIds = value.map((item) => item.id);
    setSelected_advertisers(selectedIds);
    validateField('dropdown', selectedIds);
  };
  const handleOnboardUserToDashboard = () => {
    //console.log(formData);
    let formattedData = {
      email_id: formData.email,
      new_advertiser_info: [
        {
          name: formData.advertiserName,
          id: formData.advertiserGuid,
          sfId: formData.sfid
        }
      ]
    };
    //console.log(formattedData);
    onboardUserToDashboard(formattedData);
    // let onboard_advertiser_list=advertiserList.filter(advertiser=>selected_advertisers.includes(advertiser.id))
    // console.log(onboard_advertiser_list)
  };
  const handleIAMInviteSend = () => {
    console.log('iam invite');

    if (formData.name != '' && formData.email != '') {
      sendIAMInvite(formData);
    }
  };
  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item lg={10} md={10} sm={8} xs={6}></Grid>
        <Grid item lg={2} md={4} sm={4} xs={6}>
          {/* <SearchBar/> */}
          <AnimateButton>
            <Button
              disableElevation
              disabled={false}
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={handleOpen}
            >
              Add User
            </Button>
          </AnimateButton>
          <Modal open={open} onClose={handleClose}>
            <Box sx={{ ...style, width: 400 }}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12}>
                  <Typography id="modal-title" variant="h3" component="h2" align="center">
                    Add New User Details
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item lg={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item lg={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>

                <Grid item lg={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="advertiserName"
                    label="Advertiser Name"
                    type="text"
                    fullWidth
                    value={formData.advertiserName}
                    onChange={handleChange}
                    error={!!errors.advertiserName}
                    helperText={errors.advertiserName}
                  />
                </Grid>
                <Grid item lg={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="sfid"
                    label="Sf Id"
                    type="text"
                    fullWidth
                    value={formData.sfid}
                    onChange={handleChange}
                    error={!!errors.sfid}
                    helperText={errors.sfid}
                  />
                </Grid>
                <Grid item lg={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="advertiserGuid"
                    label="Advertiser Guid"
                    type="text"
                    fullWidth
                    value={formData.advertiserGuid}
                    onChange={handleChange}
                    error={!!errors.advertiserGuid}
                    helperText={errors.advertiserGuid}
                  />
                </Grid>
                {/* <Grid item lg={12}>
                          <Autocomplete
                            multiple
                            name="dropdown"
                            options={advertiserList}
                            getOptionLabel={(option) => option.name}
                            value={value}
                            
                            onChange={(event, value) => {
                                console.log("test",value)
                                handleAutocompleteChange(event,value)
                                // setCampaigns(newValue);
                                // setValue(newValue);
                            }}
                            
                            style={{ width: "100%", overflowY: "auto", padding: "5px" }}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Advertisers" variant="outlined" error={!!errors.dropdown}
                                helperText={errors.dropdown}/>
                            )}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox checked={selected} />
                                {option.name}
                                </li>
                            )}
                            // isOptionEqualToValue={(option, value) =>
                            //     option.name === value.name
                            // }
                            renderTags={renderTags}
                            />
        
                          </Grid> */}
                <Grid item lg={12}>
                  <Grid container spacing={gridSpacing} align="center" justify="center">
                    <Grid item lg={2} md={2} sm={4} xs={2}></Grid>
                    <Grid item lg={2} md={2} sm={4} xs={3}>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item lg={4} md={2} sm={4} xs={3}>
                      <Button type="submit" onClick={handleOnboardUserToDashboard} color="primary">
                        Onboard User
                      </Button>
                      {onboardedToDashboard ? (
                        <CheckCircleIcon
                          stroke={1.5}
                          size="1rem"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            color: '#22ae2d'
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item lg={4} md={2} sm={4} xs={6}>
                      <Button type="submit" onClick={handleIAMInviteSend} color="primary">
                        Send IAM Invite
                      </Button>
                      {onboardedIAMStatus ? (
                        <CheckCircleIcon
                          stroke={1.5}
                          size="1rem"
                          style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            color: '#22ae2d'
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12}>
                  {errors.message}
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    advertiserList: state.advertiserReducer.advertiser_list,
    onboardedIAMStatus: state.userReducer.onboardedIAM,
    onboardedToDashboard: state.userReducer.onboardedToDashboard,
    iamUserList: state.userReducer.iamUserList,
    userLoginForDashboardAlreadyExist: state.userReducer.userLoginForDashboardAlreadyExist
  };
};
const mapDispatchToProps = {
  fetchAdvertiserList: fetchAdvertiserList,
  sendIAMInvite: sendIAMInvite,
  resetIAMFormData: resetIAMFormData,
  checkUserAlreadyExist: checkUserAlreadyExist,
  onboardUserToDashboard: onboardUserToDashboard
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
