import {
  FETCH_ADVERTISER_DATE_QUARTER,
  FETCH_WOW_REQUEST,
  FETCH_WOW_DATE_ERROR,
  SET_SELECTED_WOW_QUARTERS,
  FETCHED_WOW_REPORT,
  FETCHED_WOW_REPORT_ERROR,
  RESET_WOW_DATA
} from './wowTypes';
const initialState = {
  loading: false,
  advertiser_quarters: [],
  selected_advertiser_wow_quarters: [],
  wowReportData: [],
  error: []
};
const WowReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WOW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RESET_WOW_DATA: {
      return {
        ...state,
        // advertiser_quarters: [],
        // selected_advertiser_wow_quarters: [],
        wowReportData: [],
        error: []
      };
    }
    case FETCH_ADVERTISER_DATE_QUARTER:
      return {
        ...state,
        loading: false,
        advertiser_quarters: action.payload,
        selected_advertiser_wow_quarters: action.payload
      };
    case FETCH_WOW_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error_msg: 'Sorry no data found',
        error: action.payload,
        advertiser_quarters: [],
        selected_advertiser_wow_quarters: []
      };
    case SET_SELECTED_WOW_QUARTERS: {
      return {
        ...state,
        loading: false,
        selected_advertiser_wow_quarters: action.payload.selected_wow_quarter
      };
    }
    case FETCHED_WOW_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        wowReportData: [],
        error_msg: 'Sorry no data found',
        error: action.payload
      };
    }
    case FETCHED_WOW_REPORT: {
      return {
        ...state,
        loading: false,
        wowReportData: action.payload
      };
    }
    default:
      return state;
  }
};
export default WowReducer;
