import PropTypes from 'prop-types';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import User1 from 'assets/images/users/user-round.svg';
import BASE_URL from "../../../redux/config";
// assets
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { SET_MENU, SET_SIDE_MENU } from 'store/actions';
import { SET_ADVERTISER } from '../../../redux/advertiser/advertiserTypes';
// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  overflow: 'hidden',
  position: 'relative'
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const AdvertiserCard = ({ data, isLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openDashboardForAdvertiser = (data) => {
    console.log(data);

    dispatch({ type: SET_SIDE_MENU, showSideMenu: false });
    localStorage.setItem('showSideMenu', false);
    localStorage.setItem('advertiserId', data.advertiser_guid);
    localStorage.setItem('sfId', data.salesforce_account_id);
    localStorage.setItem('timeZone', data.timezone);
    localStorage.setItem('startDate', data.start_date);
    localStorage.setItem('platform', data.platform);
    let advData = {
      advertiser_guid: data.advertiser_guid,
      advertiser_name: data.advertiser_name,
      sfId: data.salesforce_account_id,
      timeZone: data.timezone,
      startDate: data.start_date,
      platform: data.platform
    };

    const platformFromStorage = localStorage.getItem('platform');

    const activePlatform = data.platform || platformFromStorage;
    if (activePlatform) {
      if (activePlatform.includes('IAP') && !activePlatform.includes('DSP')) {
        console.log("AdvCard Redirect IAP only ===>", activePlatform);
        window.location.href = `${BASE_URL}/iap`;
      } else if (activePlatform.includes('DSP') && !activePlatform.includes('IAP')) {
        console.log("AdvCard Redirect DSP only ===>", activePlatform);
        window.location.href = `${BASE_URL}/dashboard`;
      } else if (activePlatform.includes('DSP') && activePlatform.includes('IAP')) {
        console.log("AdvCard Redirect DSP & IAP ===>", activePlatform);
        window.location.href = `${BASE_URL}/dashboard`;
      }
    }
    dispatch({ type: SET_ADVERTISER, payload: advData });


    // if (data.platform != undefined && data.platform.includes('DSP') == true) {
    //   navigate(`/dashboard`);
    // } else {
    //   navigate(`/iap`);
    // }
  };
  //console.log(data)

  return (
    <>
      {isLoading ? (
        <TotalIncomeCard />
      ) : (
        <CardWrapper
          border={true}
          content={false}
          sx={{
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
              borderColor: theme.palette.primary.dark
            }
          }}
          onClick={() => openDashboardForAdvertiser(data)}
        >
          <Box sx={{ p: 2 }}>
            <List sx={{ py: 0 }}>
              <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    src={data.logo}
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      backgroundColor: theme.palette.dark.light,
                      color: theme.palette.text.primary
                    }}
                  >
                    {data.advertiser_name.charAt(0).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    py: 0,
                    mt: 0.45,
                    mb: 0.45,
                    '&:hover': {
                      color: 'white'
                    }
                  }}
                  primary={<Typography variant="h4">{data.advertiser_name}</Typography>}
                // secondary={
                //   <Typography
                //     variant="subtitle2"
                //     sx={{
                //       color: theme.palette.grey[500],
                //       mt: 0.5,
                //     }}
                //   >
                //     {data.advertiser_guid}
                //   </Typography>
                // }
                />
                <ListItemText
                  style={{
                    float: 'right',
                    top: '-16px',
                    position: 'absolute',
                    right: 0,
                    fontSize: 10,
                    fontWeight: 'normal',
                    color: 'green'
                  }}
                  primary={
                    <Typography variant="h6" style={{ color: 'green' }}>
                      {data.timezone}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

AdvertiserCard.propTypes = {
  isLoading: PropTypes.bool
};

export default AdvertiserCard;
