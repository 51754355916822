import {
  FETCH_USER_DETAILS,
  FETCH_LOGOUT,
  FETCH_FAILURE,
  FETCH_IAM_FAILURE,
  FETCH_IAM_USER_LIST,
  USER_IAM_ONBOARD_SUCCESS,
  USER_IAM_ONBOARD_FAILURE,
  RESET_ADD_USER_DATA,
  CHECK_IF_USER_EXIST,
  ADD_USER_DETAILS_TO_DASHBOARD,
  FETCH_ALL_DASHBOARD_USER_LIST,
  FETCHED_ADVERTISERS_FOR_USER_BASED_ON_EMAIL,
  FETCHED_USER_TYPE,
  FETCH_USER_TYPE_ERROR,
  UPDATE_USER_ADVERTISER_SETTINGS,
  USER_DETAILS_STORED_SUCCESS,
  USER_UI_SETTINGS_ON_TIMEZONE,
  FETCH_USER_PLATFORMS
} from './userTypes';
const initialState = {
  login: false,
  userEmail: '',
  userName: '',
  userId: '',
  authorized: true,
  iamUserList: [],
  error: '',
  loading: false,
  onboardedIAM: false,
  onboardedToDashboard: false,
  userLoginForDashboardAlreadyExist: false,
  dashboardUserList: [],
  platforms: [],
  advertisersForUserTimeZone: {
    utcAdvertisers: [],
    orgNames: [],
    jstAdvertisers: []
  },
  fetchedUserRoleType: 'client',
  userDataSaved: false,
  overAllDisplaySettings: {
    publisher_app_report: true,
    creative_report: true,
    overview: true,
    mmp_installs: true,
    mmp_events: false,
    performance_creative_type: true,
    performance_mmp_installs: true,
    event_report: true
  }
};

const userReducer = (state = initialState, action) => {
  //console.log(action.payload);
  switch (action.type) {
    case FETCH_USER_DETAILS: {
      return {
        ...state,
        userEmail: action.payload.email,
        userName: action.payload.name,
        userId: action.payload.userId,
        authorized: true,
        login: true
      };
    }
    case FETCH_LOGOUT:
      console.log('FETCH_LOGOUT');
      return {
        ...state,
        login: false,
        authorized: false
      };
    case FETCH_FAILURE:
      console.log('FETCH_LOGOUT');
      return {
        ...state,
        authorized: false,
        login: false
      };
    case FETCH_USER_TYPE_ERROR:
      return {
        ...state,
        fetchedUserRoleType: '',
        error: 'user details not found'
      };
    case FETCH_IAM_FAILURE:
      return {
        ...state,
        iamUserList: [],
        error: action.payload.error
      };
    case FETCH_IAM_USER_LIST:
      return {
        ...state,
        iamUserList: action.payload
      };
    case USER_IAM_ONBOARD_SUCCESS:
      return {
        ...state,
        onboardedIAM: true
      };
    case USER_DETAILS_STORED_SUCCESS:
      return {
        ...state,
        userDataSaved: true
      };
    case USER_IAM_ONBOARD_FAILURE:
      return {
        ...state,
        onboardedIAM: false,
        error: 'Sorry was not able to Onboard. Try Again'
      };
    case RESET_ADD_USER_DATA:
      return {
        ...state,
        onboardedIAM: false,
        onboardedToDashboard: false,
        loading: false,
        error: '',
        userDataSaved: false
      };
    case CHECK_IF_USER_EXIST:
      return {
        ...state,
        userLoginForDashboardAlreadyExist: action.payload
      };
    case ADD_USER_DETAILS_TO_DASHBOARD:
      return {
        ...state,
        onboardedToDashboard: true
      };
    case FETCH_ALL_DASHBOARD_USER_LIST:
      return {
        ...state,
        dashboardUserList: action.payload
      };
    case FETCHED_ADVERTISERS_FOR_USER_BASED_ON_EMAIL:
      return {
        ...state,
        advertisersForUserTimeZone: action.payload
      };
    case FETCHED_USER_TYPE:
      return {
        ...state,
        fetchedUserRoleType: action.payload.user_type
      };
    case USER_UI_SETTINGS_ON_TIMEZONE:
      return {
        ...state,
        overAllDisplaySettings: action.payload
        //{
        //   publisher_app_report:action.payload.details.publisher_app_report,
        //   creative_report:action.payload.details.creative_report,
        //   overview:action.payload.details.overview,
        //   bundle_report:action.payload.bundle_report
        // }
      };
    case FETCH_USER_PLATFORMS:
      return {
        ...state,
        platforms: action.payload
      };
    default:
      return state;
  }
};
export default userReducer;
