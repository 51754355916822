import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import LoaderCircular from 'ui-component/LoaderCircular';
import { fetchReportDataForSkanReporting } from '../../redux/reporting/reportingActions';
import '../Dashboard/styleSearch.scss';
const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'campaign_name',
    headerName: 'Campaign Name',
    width: 350,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },
  {
    field: 'app_bundle',
    headerName: 'App Bundle',
    // flex: 1,
    width: 100,
    hideable: false,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },

  {
    field: 'country',
    headerName: 'Country',
    // flex: 1.5,
    width: 110,
    // align: 'center',
    // headerAlign: 'center'
  },
  {
    field: 'os_name',
    headerName: 'OS',
    // flex:1,
    width: 110,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    valueFormatter: (params) => splitDigits(params.value),
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    valueFormatter: (params) => splitDigits(params.value),
    // flex:1,
    width: 130,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost',
    headerName: 'Media Cost',
    valueFormatter: (params) => '$ ' + roundToTwoDecimal(params.value),
    // flex:1,
    width: 130,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'installs',
    headerName: 'MMP Installs',
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'skan_installs',
    headerName: 'SKAN Installs',
    sort: 'desc',
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ctr',
    headerName: 'CTR',
    valueFormatter: (params) => roundToTwoDecimalPercent(params.value),
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  // {
  //   field: 'ecpe',
  //   headerName: 'Total eCPE',
  //   valueFormatter: (params) => roundToTwoDecimal(params.value),
  //   align: 'center',
  //   headerAlign: 'center',
  //   width: 100
  //   // flex:1
  // },
  {
    field: 'ecpi',
    headerName: 'Total eCPI',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    // flex: 1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  // {
  //   field: 'mmp_ecpe',
  //   headerName: 'MMP eCPE',
  //   valueFormatter: (params) => roundToTwoDecimal(params.value),
  //   align: 'center',
  //   headerAlign: 'center',
  //   width: 100
  //   // flex:1
  // },
  {
    field: 'mmp_ecpi',
    headerName: 'MMP eCPI',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    align: 'center',
    headerAlign: 'center',
    width: 100
    // flex:1
  },
  // {
  //   field: 'skan_ecpe',
  //   headerName: 'SKAN eCPE',
  //   valueFormatter: (params) => roundToTwoDecimal(params.value),
  //   align: 'center',
  //   headerAlign: 'center',
  //   width: 100
  //   // flex:1
  // },
  {
    field: 'skan_ecpi',
    headerName: 'SKAN eCPI',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    align: 'center',
    headerAlign: 'center',
    width: 100
    // flex:1
  },
  {
    field: 'ecpc',
    headerName: 'eCPC',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    align: 'center',
    headerAlign: 'center',
    width: 100
    // flex:0.8
  },
  {
    field: 'ecpm',
    headerName: 'eCPM',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  }

  // {
  //   field: 'Country',
  //   headerName: 'Country',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];
function splitDigits(num) {
  return Number(num).toLocaleString();
}
function roundToTwoDecimal(value) {
  if (value == '-999') return '-';
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
function roundToTwoDecimalPercent(value) {
  if (value == '-999') return '-';
  const roundedValue = Math.round((value * 100 + Number.EPSILON) * 100) / 100; // Multiply by 100 to convert to percentage
  return `${roundedValue}%`; // Append '%' sign
}
const ReportDataSkanView = ({ fetchDataForReportingDatatable, reportingData, fetchReportDataForSkanReporting, loading, error }) => {
  useEffect(() => {
    //fetchDataForReportingDatatable();
    //console.log(reportingData);
    // fetchReportData()
  }, [reportingData]);
  // console.log("loading==>",loading)
  const getRowHeight = (params) => {
    // console.log(params)
    const column2Value = params.model.campaign_name;
    // console.log(column2Value)
    if (column2Value.length > 110) {
      return 150; // Return the desired row height in pixels
    } else if (column2Value.length > 100) {
      return 130; // Return the desired row height in pixels
    } else {
      return 60;
    }
  };
  return (
    <>
      {loading ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Top 500 Publisher Apps Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <div style={{ height: '400px', width: '100%' }}>
                {' '}
                <LoaderCircular show={true} />
              </div>
            </Grid>
          </Grid>
        </MainCard>
      ) : reportingData == 0 ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Top 500 Publisher Apps Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography style={{ textAlign: 'center' }}>
                {/* Please click <b>Apply Filter</b> to view data */}
                {error.length != 0 ? (
                  'No Data Found'
                ) : (
                  <span>
                    Please click <b>Apply Filter</b> to view data
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Top 500 Publisher Apps Report</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <Box>
                    <div style={{ minHeight: '400px', width: '100%' }}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            position: 'relative',
                            top: '50%',
                            left: '50%'
                          }}
                        />
                      ) : (
                        <DataGrid
                          rows={reportingData}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10
                              }
                            },
                            sorting: {
                              sortModel: [{ field: 'media_cost', sort: 'desc' }] // Default sort on 'age' column
                            }
                          }}
                          components={{
                            LoadingOverlay: CircularProgress
                          }}
                          loading={loading}
                          pageSizeOptions={[10, 20, 30]}
                          slots={{ toolbar: GridToolbar }}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableDensitySelector
                          // sortModel={[
                          //   {
                          //     field: "installs",
                          //     sort: "desc", // Set 'desc' for descending, 'asc' for ascending
                          //   },
                          // ]}
                          autoHeight
                          // getRowHeight={getRowHeight}
                          getRowHeight={() => 'auto'}
                          className="custom-datagrid"
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    reportingData: state.reportingReducer.reportingData,
    loading: state.reportingReducer.bundleReportLoading,
    error: state.reportingReducer.error
  };
};
const mapDispatchToProps = {
  fetchReportDataForSkanReporting: fetchReportDataForSkanReporting
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportDataSkanView);
