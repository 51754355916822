import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { Typography, Grid, Paper } from '@mui/material';
import SearchBar from 'components/Dashboard/SearchBar';
import LoaderCustom from 'ui-component/LoaderCustom';
import RawEventSkanView from '../components/RawEvents/RawEventSkanView';
import RawEventNonSkanView from 'components/RawEvents/RawEventNonSkanView';
const RawEventsPage = ({ advertiserList, isSkan, timeZone }) => {
  return (
    <>
      {advertiserList.length == 0 ? (
        <LoaderCustom />
      ) : (
        <>
          <Paper elevation spacing={gridSpacing} className="stickyBox">
            <SearchBar />
          </Paper>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {timeZone === "JST" ?
                    (isSkan === true ? (<RawEventNonSkanView />) : (<RawEventNonSkanView />))
                    : isSkan === true ? (<RawEventSkanView />) : (< RawEventNonSkanView />)
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.creativeReducer.creativeLoading,
    advertiserList: state.advertiserReducer.advertiser_list,
    isSkan: state.campaignReducer.isSkan,
    timeZone: state.advertiserReducer.timeZone,
  };
};
export default connect(mapStateToProps, null)(RawEventsPage);
