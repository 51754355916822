import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent } from '@mui/material';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import MainCard from 'ui-component/cards/MainCard';
import LoaderCircular from 'ui-component/LoaderCircular';
import { gridSpacing } from 'store/constant';
import '../Dashboard/styleSearch.scss';

const metricsLabels = [
  { value: 'campaign_name', label: 'Campaign Name' },
  { value: 'country', label: 'Country' }
];

const measuresLabel = [
  //   { value: "impressions", label: "Impressions" },
  { value: 'clicks', label: 'Clicks' },
  { value: 'installs', label: 'Installs' }
];
const IapAnalysisReport = ({ performanceData, performanceLoading, campaign_list, error, isDollar, campaigns_main_data }) => {
  const [showChart, setShowChart] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [metrics, setMetrics] = useState('campaign_name');
  const [measures, setMeasures] = useState('installs');

  //console.log('Performance Dat===>', campaigns_main_data, performanceLoading);

  const [series, setSeries] = useState([
    {
      name: 'Media Cost',
      data: []
    }
  ]);
  const customDateSort = (a, b) => new Date(a.date) - new Date(b.date);
  const metricsData = useMemo(() => {
    if (campaigns_main_data.length === 0) return { dates: [], data: [] };

    const fetchedData = campaigns_main_data.sort(customDateSort);
    const dates = [...new Set(fetchedData.map((entry) => entry.date))];
    let metricsData = [];

    const uniqueMetrics = [...new Set(fetchedData.map((entry) => entry[metrics]))];

    uniqueMetrics.forEach((x) => {
      const filteredData = fetchedData.filter((item) => item[metrics] === x);
      const result = dates.map((date) => {
        const filtered = filteredData.filter((item) => item.date === date);
        return filtered.reduce((sum, item) => sum + item[measures], 0);
      });
      metricsData.push({ name: x, data: result });
    });

    return { dates, data: metricsData };
  }, [campaigns_main_data, metrics, measures]);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: { position: 'bottom', offsetX: -10, offsetY: 0 }
          }
        }
      ],
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']
        }
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      plotOptions: { bar: { horizontal: false, columnWidth: '50%' } },
      xaxis: { type: 'category', categories: metricsData.dates },
      yaxis: {
        labels: {
          formatter: (value) => formatNumberAbbreviated(value)
        }
      },
      tooltip: {
        enabled: true
        // custom: function (series, seriesIndex, dataPointIndex, w) {
        //   let details=series.series

        //   console.log(series,series.dataPointIndex,w)
        //   const formattedValue = formatTooltipValue(
        //     details[series.seriesIndex][series.dataPointIndex]
        //   );
        //  //console.log(formattedValue)
        //   return (
        //     '<div class="custom-tooltip">' +
        //     "<span>" +
        //     series.w.config.series[series.seriesIndex].name +
        //     "</span>" +
        //     "<span>" +
        //     formattedValue +
        //     "</span>" +
        //     "</div>"
        //   );
        // },
      }
    },
    series: metricsData.data
  });

  //console.log('chartData Series ===>', chartData,);

  function formatNumberAbbreviated(number) {
    if (number == 0) {
      return 0;
    }
    if (Number.isNaN(number)) {
      return null;
    }
    if (number < 1000) {
      return number;
    }
    const suffixes = ['', 'K', 'M', 'B'];

    const formatNumber = (num) => {
      const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places
      return roundedNum.toString().replace(/\.0+$/, ''); // Remove trailing zeros after decimal point
    };

    const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
    const abbreviatedNumber = number / Math.pow(10, suffixIndex * 3);

    return `${formatNumber(abbreviatedNumber)}${suffixes[suffixIndex]}`;
  }
  function formatTooltipValue(value) {
    return value.toFixed(2);
  }
  useEffect(() => {
    if (campaigns_main_data.length !== 0 && performanceLoading === false) {
      //console.log('Processing new performance data...');

      //console.log('metricsData.dates', metricsData.dates);
      setChartData((prevChartData) => {
        const newChartData = {
          ...prevChartData,
          options: {
            ...prevChartData.options,
            xaxis: {
              ...prevChartData.options.xaxis,
              categories: metricsData.dates
            },
            yaxis: {
              ...prevChartData.options.yaxis,
              labels: {
                formatter: (value) => formatNumberAbbreviated(value)
              }
            }
          },
          series: metricsData.data
        };
        // console.log("New chartData ===>", JSON.stringify(newChartData, null, 2));
        return newChartData;
      });
      setShowChart(true);
      //force update chartData - handle Media Cost Ghost error
      setForceUpdate((prev) => !prev);
    }
  }, [campaigns_main_data, performanceLoading, metricsData]);
  // console.log(chartData,performanceLoading);
  //console.log('performanceData', chartData,"metricsData",metricsData);
  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">Performance</Typography>
            </Grid>
            <Grid item>
              <TextField select value={metrics} onChange={(e) => setMetrics(e.target.value)}>
                {metricsLabels.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <Typography variant="h3">vs</Typography>
            </Grid>
            <Grid item>
              <TextField select value={measures} onChange={(e) => setMeasures(e.target.value)}>
                {measuresLabel.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} lg={12}>
              <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                {/* <Grid item xs={12}>
                  <Typography variant="h3">Performance</Typography>
                </Grid> */}
                <Grid item xs={12}>
                  {performanceLoading == true ? (
                    <div style={{ height: '200px', width: '100%' }}>
                      {' '}
                      <LoaderCircular show={true} />
                    </div>
                  ) : campaigns_main_data.length == 0 ? (
                    <div className="topPerformerDataContainer">
                      <Typography style={{ textAlign: 'center' }}>
                        {error.length != 0 ? (
                          'No Data Found'
                        ) : (
                          <span>
                            Please click <b>Apply Filter</b> to view data
                          </span>
                        )}
                      </Typography>
                    </div>
                  ) : performanceLoading == false && showChart == true ? (
                    <Chart
                      key={forceUpdate} // Force re-render by changing key
                      options={chartData.options}
                      series={chartData.series}
                      type="bar"
                    />
                  ) : (
                    ''
                  )}
                  {/* {showChart!=true?
                              <div style={{height:'200px',width:'100%'}}> 
                                <LoaderCircular show={true}/>
                              </div>:<Chart options={chartData.options} series={chartData.series} type="line" />} */}
                  {/* {showChart==true?<Chart options={chartData.options} series={chartData.series} type="bar" />:''} */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.IAPReducer.loading,
    performanceData: state.campaignReducer.performanceData,
    dataFetched: state.campaignReducer.dataFetched,
    campaign_list: state.advertiserReducer.selected_campaigns,
    campaigns_main_data: state.IAPReducer.graphData,
    performanceLoading: state.IAPReducer.loading,
    error: state.campaignReducer.error,
    isDollar: state.campaignReducer.isDollar,
    timeZone: state.advertiserReducer.timeZone
  };
};
export default connect(mapStateToProps, null)(IapAnalysisReport);
