import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
// login option 3 routing

import AdminPanel from '../pages/AdminPanel';
import TimeZonePage from '../pages/TimeZonePage';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/pages/admin/onboarduser',
      element: <AdminPanel />
    },
    {
      path: '/pages/admin/usertimezone',
      element: <TimeZonePage />
    }
  ]
};

export default AuthenticationRoutes;
