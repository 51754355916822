import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Drawer, Stack, useMediaQuery, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const collapsedDrawerWidth = 110;
  const expandedTopPosition = '88px';
  const collapsedTopPosition = '105px'; // Adjust this value to slide down as needed

  const expandedButtonSize = 40; // Normal size for expanded state
  const collapsedButtonSize = 25; // Smaller size for collapsed state

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: isCollapsed ? '8px' : '16px',
            paddingRight: isCollapsed ? '8px' : '16px'
          }}
        >
          <MenuList isCollapsed={isCollapsed} />
          <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
          </Stack>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList isCollapsed={isCollapsed} />
          <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
          </Stack>
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? (isCollapsed ? collapsedDrawerWidth : drawerWidth) : 'auto'
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: isCollapsed ? collapsedTopPosition : expandedTopPosition
            },
            transition: theme.transitions.create(['width', 'top'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            })
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
      <IconButton
        onClick={handleCollapseToggle}
        sx={{
          position: 'fixed',
          top: matchUpMd ? '100px' : '50px',
          left: isCollapsed ? `${collapsedDrawerWidth - collapsedButtonSize / 2}px` : `${drawerWidth - expandedButtonSize / 2}px`,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '50%',
          boxShadow: theme.shadows[3],
          zIndex: 1300,
          width: isCollapsed ? collapsedButtonSize : expandedButtonSize,
          height: isCollapsed ? collapsedButtonSize : expandedButtonSize,
          transition: theme.transitions.create(['left', 'width', 'height'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard
          }),
          '&:hover': {
            backgroundColor: theme.palette.primary.light
          }
        }}
      >
        {isCollapsed ? <MenuIcon fontSize="small" /> : <MenuIcon fontSize="medium" />}
      </IconButton>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
