import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Typography, Grid, Paper } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { SET_SIDE_MENU } from 'store/actions';
import LoaderCustom from 'ui-component/LoaderCustom';
import SearchBarWow from 'components/Dashboard/SearchBarWow';
import WowNonSkanReport from '../components/Wow/WowNonSkanReport';
import WowSkanReport from 'components/Wow/WowSkanReport';
import WowNonSkanView from '../components/Wow/WowNonSkanView';
import WowSkanView from 'components/Wow/WowSkanView';
import './style.scss';
const WowPage = ({ isSkan, advertiserList, advertiser_campaign_fetching }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('showSideMenu', true);
    dispatch({ type: SET_SIDE_MENU, showSideMenu: true });
  }, []);
  return (
    <>
      {advertiserList.length == 0 || advertiser_campaign_fetching == true ? (
        <LoaderCustom />
      ) : (
        <>
          <Paper elevation spacing={gridSpacing} className="stickyBox">
            <SearchBarWow />
          </Paper>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {isSkan == true ? <WowSkanReport /> : <WowNonSkanReport />}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {isSkan == true ? <WowSkanView /> : <WowNonSkanView />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            
                        {isSkan == true ? (
                            <WowSkanReport />
                        ) : (
                            <WowNonSkanReport />
                        )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    campaignList: state.advertiserReducer.campaign_list,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
    advertiser_name: state.advertiserReducer.advertiser_name,
    selected_campaigns: state.campaignReducer.campaign_list,
    selected_campaigns_to_fetch_data: state.advertiserReducer.selected_campaigns,
    isSkan: state.campaignReducer.isSkan,
    userEmail: state.userReducer.userEmail,
    advertiserList: state.advertiserReducer.advertiser_list,
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching
  };
};
export default connect(mapStateToProps, null)(WowPage);
