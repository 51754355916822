import { SET_SIDEBAR_COLLAPSE } from './sidebarTypes'; // Updated import path

const initialState = {
  sidebarCollapsed: false
  // other state...
};

export default function customizationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR_COLLAPSE:
      return {
        ...state,
        sidebarCollapsed: action.payload
      };
    default:
      return state;
  }
}
