import axios from 'axios';
import { FETCH_RAW_EVENT_REQUEST, FETCH_RAW_EVENT_ERROR, FETCH_RAW_EVENT_DATA, RESET_RAW_EVENT_DATA } from './rawEventTypes';
import BASE_URL from '../config';
axios.defaults.timeout = 120000;
const timeout = 120000;

export const fetchRequestEvents = (msg) => {
  return {
    type: FETCH_RAW_EVENT_REQUEST,
    payload: [msg]
  };
};
export const resetRawEventsData = () => {
  return {
    type: RESET_RAW_EVENT_DATA,
    payload: []
  };
};
export const fetchRawEventSkanData = (campaign_list) => (dispatch, getState) => {
  dispatch(fetchRequestEvents('loading'));
  axios({
    method: 'post',
    url: `${BASE_URL}/getSkanRawEventsReport`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log(response.data);
      if (response.data.raweventdata.length > 0) {
        return dispatch(fetchedRawEventsDetails(response.data.raweventdata));
      } else {
        return dispatch(fetchRawEventRequestError('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchRawEventRequestError('Not able to fetch data. Please try again'));
      //return Promise.reject(error)
    });
};

export const fetchRawEventNonSkanData = (campaign_list) => (dispatch, getState) => {
  dispatch(fetchRequestEvents('loading'));
  axios({
    method: 'post',
    url: `${BASE_URL}/getNonSkanRawEventsReport`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log(response.data.raweventdata)
      if (response.data.raweventdata.length > 0) {
        return dispatch(fetchedRawEventsDetails(response.data.raweventdata));
      } else {
        return dispatch(fetchRawEventRequestError('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchRawEventRequestError('Not able to fetch data. Please try again'));
      //return Promise.reject(error)
    });
};

function detectKeys(jsonData) {
  const keys = new Set();

  jsonData.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      keys.add(key);
    });
  });
  return Array.from(keys);
}
export const fetchedRawEventsDetails = (data) => {
  // Call the function with your JSON data
  const detectedKeys = detectKeys(data);
  ////console.log("detectedKeys",detectedKeys)
  const rawEventData = data.map((item, id = index) => ({ id, ...item }));

  return {
    type: FETCH_RAW_EVENT_DATA,
    payload: rawEventData
  };
};
export const fetchRawEventRequestError = (error) => {
  return {
    type: FETCH_RAW_EVENT_ERROR,
    payload: error
  };
};
