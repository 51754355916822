import React, { useEffect, useState } from 'react';

const ScrollIndicator = ({ containerRef }) => {
    const [showRightIndicator, setShowRightIndicator] = useState(false);
    const [showLeftIndicator, setShowLeftIndicator] = useState(false);

    const checkScroll = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setShowRightIndicator(scrollLeft < scrollWidth - clientWidth);
            setShowLeftIndicator(scrollLeft > 0);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            checkScroll();
            container.addEventListener('scroll', checkScroll);
            window.addEventListener('resize', checkScroll);

            return () => {
                container.removeEventListener('scroll', checkScroll);
                window.removeEventListener('resize', checkScroll);
            };
        }
    }, [containerRef]);

    if (!showLeftIndicator && !showRightIndicator) return null;

    return (
        <>
            {showLeftIndicator && (
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: '50px',
                        background: 'linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0))',
                        zIndex: 1,
                        pointerEvents: 'none'
                    }}
                />
            )}
            {showRightIndicator && (
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: '50px',
                        background: 'linear-gradient(to left, rgba(255,255,255,0.9), rgba(255,255,255,0))',
                        zIndex: 1,
                        pointerEvents: 'none'
                    }}
                />
            )}
        </>
    );
};

export default ScrollIndicator;