import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddUser from '../components/admin/addUser';
import ListUsers from 'components/admin/listUsers';
import MainCard from 'ui-component/cards/MainCard';
import { Grid, Modal, Box, Typography } from '@mui/material';

const AdminPanel = ({ email }) => {
  useEffect(() => {
    console.log('email', email);
  }, []);
  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              {/* <SearchBar/> */}
              Admin
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AddUser />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ListUsers />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <AddUser/> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    experimentList: state.skanRateCardStoreReducer,
    email: state.userReducer.userEmail
  };
};
export default connect(mapStateToProps, null)(AdminPanel);
