import axios from 'axios';
import {
  FETCH_ADVERTISER_DATE_QUARTER,
  FETCH_WOW_REQUEST,
  FETCH_WOW_DATE_ERROR,
  FETCHED_WOW_REPORT,
  FETCHED_WOW_REPORT_ERROR,
  RESET_WOW_DATA
} from './wowTypes';
import BASE_URL from '../config';
axios.defaults.timeout = 120000;
const timeout = 120000;
export const fetchRequest = () => {
  return {
    type: FETCH_WOW_REQUEST,
    payload: []
  };
};
export const resetWowData = () => {
  return {
    type: RESET_WOW_DATA,
    payload: []
  };
};
export const fetchRequestWow = (msg) => {
  return {
    type: FETCH_WOW_REQUEST,
    payload: [msg]
  };
};
export const fetchWowAdvertiserQuarters = () => (dispatch, getState) => {
  axios({
    method: 'post',
    url: `${BASE_URL}/getWowAdvertiserQuarter`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log('Wow', response.data.quarters, response.data.quarters.length);
      if (response.data.quarters.length > 0) {
        //console.log(response.data[0].bundles)
        // console.log("Wow",response.data.quarters);
        return dispatch(fetchWowQuarters(response.data.quarters));
      } else {
        return dispatch(fetchWowQuartersFailure);
      }
    })
    .catch(function (error) {
      //console.log('Show error notification!', error, error.response);
      if (error.response.data.statusCode == 404) {
        dispatch(fetchWowQuartersFailure('No Data Found'));
      } else {
        return dispatch(fetchWowQuartersFailure('Not able to fetch data. Please retry'));
      }
    });
};
export const fetchWowReportNonSkanData = (campaign_list) => (dispatch, getState) => {
  dispatch(fetchRequestWow('loading'));
  axios({
    method: 'post',
    url: `${BASE_URL}/getNonSkanWowReport`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone,
      wow_quarters: getState().wowReducer.selected_advertiser_wow_quarters
    },
    timeout: timeout
  })
    .then((response) => {
      console.log(response.data);
      if (response.data.length > 0) {
        return dispatch(fetchedWowReportDetails(response.data));
      } else {
        return dispatch(fetchWowReportFailure('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchWowReportFailure('Not able to fetch data. Please try again'));
      //return Promise.reject(error)
    });
};
export const fetchWowReportSkanData = (campaign_list) => (dispatch, getState) => {
  dispatch(fetchRequestWow('loading'));
  axios({
    method: 'post',
    url: `${BASE_URL}/getSkanWowReport`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone,
      wow_quarters: getState().wowReducer.selected_advertiser_wow_quarters
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log(response.data.raweventdata)
      if (response.data.length > 0) {
        return dispatch(fetchedWowReportDetails(response.data));
      } else {
        return dispatch(fetchWowReportFailure('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchWowReportFailure('Not able to fetch data. Please try again'));
      //return Promise.reject(error)
    });
};
export const fetchWowQuarters = (data) => {
  //console.log('data', data);
  // Call the function with your JSON data
  //const detectedKeys = detectKeys(data);
  //console.log("detectedKeys",detectedKeys)
  const wowDetails = data.map((item, id = index) => ({ id, ...item }));
  //console.log('wowDetails', data);
  return {
    type: FETCH_ADVERTISER_DATE_QUARTER,
    payload: data
  };
};
export const fetchWowQuartersFailure = (error) => {
  return {
    type: FETCH_WOW_DATE_ERROR,
    payload: error
  };
};
function detectKeys(jsonData) {
  const keys = new Set();

  jsonData.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      keys.add(key);
    });
  });
  return Array.from(keys);
}
export const fetchedWowReportDetails = (data) => {
  // Call the function with your JSON data
  //console.log(data);
  const detectedKeys = detectKeys(data);
  ////console.log("detectedKeys",detectedKeys)
  const rawEventData = data.map((item, id = index) => ({ id, ...item }));
  const sortedData = data.sort((a, b) => {
    if (a.year === b.year) {
      return parseInt(a.week_number) - parseInt(b.week_number);
    }
    return parseInt(a.year) - parseInt(b.year);
  });
  //console.log('sortedData', sortedData);
  // console.log("rawEventData",rawEventData)
  return {
    type: FETCHED_WOW_REPORT,
    payload: rawEventData
  };
};

export const fetchWowReportFailure = (error) => {
  return {
    type: FETCHED_WOW_REPORT_ERROR,
    payload: error
  };
};
