import React, { useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { connect } from 'react-redux';
import {
  Grid,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Tab,
  CustomTabPanel,
  Chip,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { fetchOrgList } from '../../redux/advertiser/advertiserActions';
const EditUserMappingDetails = ({ details, fetchOrgList, org_list }) => {
  // console.log(details.details)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    advertiserList: [],
    advertiserName: '',
    advertiserGuid: '',
    userType: '',
    region: []
  });
  const [value, setValue] = React.useState('1');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userType, setUserType] = useState('internal');
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleInputChange = (event, value) => {
    console.log(value.map((item) => item.org_name));
    setFormData({
      ...formData,
      region: value.map((item) => item.org_name)
    });
  };
  useEffect(() => {
    if (formData.email != '') {
      fetchOrgList();
    }
  }, []);
  useEffect(() => {
    console.log(org_list);
  }, [org_list]);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={12} md={12} sm={12}>
        <Typography id="modal-title" variant="h3" component="h2" align="center">
          Edit Onboarded User Details
        </Typography>
        <Divider />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <FormLabel id="demo-radio-buttons-group-label">Name</FormLabel>
        <TextField autoFocus margin="dense" name="name" type="text" fullWidth value={formData.name} onChange={handleChange} />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <FormLabel id="demo-radio-buttons-group-label">Email Address</FormLabel>
        <TextField autoFocus margin="dense" name="email" type="email" fullWidth value={formData.email} onChange={handleChange} />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        {/* <Select
                labelId="demo-simple-select-label" name="userType"
                id="demo-simple-select"
                value={formData.userType}
                label="User Type"
                onChange={handleChange}
                >
                <MenuItem value={10} default>Internal</MenuItem>
                <MenuItem value={20}>Admin</MenuItem>
                <MenuItem value={30}>Client</MenuItem>
                </Select> */}
        <FormLabel id="demo-radio-buttons-group-label">User Role</FormLabel>
        <RadioGroup
          row
          aria-labelledby="userType"
          label="Role"
          name="userType"
          value={formData.userType}
          defaultValue="client"
          onChange={handleChange}
        >
          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
          <FormControlLabel value="internal" control={<Radio />} label="Internal" />
          <FormControlLabel value="client" control={<Radio />} label="Client" />
        </RadioGroup>
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <FormLabel id="demo-radio-buttons-group-label">Region</FormLabel>
        <Autocomplete
          multiple
          options={org_list}
          onChange={handleInputChange}
          getOptionLabel={(option) => option.org_name}
          renderInput={(params) => <TextField {...params} placeholder="Select Region" />}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <TabContext value={value} sx={{ border: 1, borderColor: 'divider' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="UTC Advertisers" value="1" />
              <Tab label="JST Advertisers" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                test
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
        </TabContext>
      </Grid>
      <Grid item lg={12} md={12} sm={12} align="right">
        <Button type="submit" variant="outlined" color="primary">
          Save Details
        </Button>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    org_list: state.advertiserReducer.org_list
  };
};
const mapDispatchToProps = {
  fetchOrgList: fetchOrgList
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUserMappingDetails);
