import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { gridDataRowIdsSelector } from '@mui/x-data-grid';
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  CssBaseline,
  AppBar,
  Input,
  Select,
  TextField,
  Autocomplete,
  Checkbox,
  Box,
  FormGroup
} from '@mui/material';
import {
  startOfYesterday,
  format,
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay
} from 'date-fns';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import IconButton from '@mui/material/IconButton';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HorizontalRuleTwoToneIcon from '@mui/icons-material/HorizontalRuleTwoTone';
import Switch from '@mui/material/Switch';
import './styleSearch.scss';
import { SET_SEARCH_DATES } from '../../redux/campaign/campaignTypes';

const DateSelectorIAP = ({ advertiser_campaign_fetching, performanceLoading, widzardLoading, reportLoading, advertiserStartDate }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -2));
  const [endDate, setEndDate] = useState(addDays(new Date(), -1)); //useState(startOfYesterday() );
  const [selectionCount, setSelectionCount] = useState(0);
  const [disable, setDisable] = useState(false);
  const dateRangePickerRef = useRef(null);
  const [minimumDate, setMinimumDate] = useState(new Date(2024, 1, 1));
  const dispatch = useDispatch();
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3))
  };
  const getFormattedDate = (date) => {
    return format(date, 'dd/MMM/yy');
  };
  const getFormattedDateForAPI = (date) => {
    return format(date, 'yyyy-MM-dd');
  };
  const handleSelectRange = (ranges) => {
    const count = selectionCount + 1;
    setSelectionCount(count);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    if (count === 2) getFilteredData(ranges.selection.startDate, ranges.selection.endDate);
  };
  const getFilteredData = (startDate, endDate) => {
    setSelectionCount(0);
    //setShowCalendar(!showCalendar);
    dispatch({
      type: SET_SEARCH_DATES,
      payload: {
        startDate: getFormattedDateForAPI(startDate),
        endDate: getFormattedDateForAPI(endDate)
      }
    });
  };
  const handleApply = () => {
    setShowCalendar(false);
  };
  const handleCancel = () => {
    setShowCalendar(false);
  };
  useEffect(() => {
    //console.log('advertiserStartDate', advertiserStartDate);
    setMinimumDate(new Date(advertiserStartDate));
  }, [advertiserStartDate]);
  useEffect(() => {
    if (selectionCount == 0) {
      //console.log("selectionCount",selectionCount,startDate,endDate)
      dispatch({
        type: SET_SEARCH_DATES,
        payload: {
          startDate: getFormattedDateForAPI(startDate),
          endDate: getFormattedDateForAPI(endDate)
        }
      });
    }
  }, []);
  useEffect(() => {
    // console.log(
    //   'advertiser_campaign_fetching',
    //   advertiser_campaign_fetching,
    //   'performanceLoading',
    //   performanceLoading,
    //   'widzardLoading',
    //   widzardLoading,
    //   'reportLoading',
    //   reportLoading
    // );
    // if(advertiser_campaign_fetching==true || performanceLoading==true || widzardLoading==true){
    //     setDisable(true)
    // }
    // else{
    //     setDisable(false)
    // }
  }, [advertiser_campaign_fetching, performanceLoading]);
  const handleOkClick = () => {
    // Logic to handle OK button click (e.g., close the calendar)
    console.log('OK button clicked');
  };
  const staticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfYesterday
      })
    },
    {
      label: 'Last 3 Months',
      range: () => ({
        startDate: defineds.startOfLastThreeMonths,
        endDate: defineds.endOfLastMonth
      })
    }
  ]);

  return (
    <div className="date-filter" style={{ 'justify-content': 'flex-start' }}>
      {/* <div  onClick={()=>handleLogin1()}>
                        <div>{getFormattedDate(startDate)}-{getFormattedDate(endDate)}</div>
                   
                        <CalendarMonthIcon fontSize="small" className="calendar-logo" />
                    </div> */}
      <div className={disable ? 'calendarDisable' : 'calendarEnable'}>
        <Box className="selected-date blue-font flex-row justify-around" onClick={setShowCalendar} sx={{ width: '100px', height: '40px' }}>
          <div className="selectedDateRangeInputBox">
            {getFormattedDate(startDate)} <HorizontalRuleTwoToneIcon fontSize="medium" sx={{ color: 'rgb(13 13 13)' }} />{' '}
            {getFormattedDate(endDate)}
          </div>

          <CalendarMonthIcon fontSize="medium" className="calendar-logo" sx={{ color: 'rgb(0, 143, 251)' }} />
        </Box>
        <div className={`dateBoxContainerIAP ${showCalendar ? 'show' : 'hide'}`} ref={dateRangePickerRef}>
          <DateRangePicker
            className={`calendar ${showCalendar ? 'show' : 'hide'}`}
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
                key: 'selection'
              }
            ]}
            calendarFocus="backwards"
            minDate={minimumDate}
            maxDate={startOfYesterday()}
            onChange={handleSelectRange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            preventSnapRefocus={true}
            inputRanges={[]}
            staticRanges={staticRanges}
          />
          <div className="calendarFooter">
            <Button variant="outlined" onClick={handleCancel} disabled="">
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button variant="outlined" onClick={handleApply} disabled="">
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    advertiserStartDate: state.advertiserReducer.startDate
  };
};
export default connect(mapStateToProps, null)(DateSelectorIAP);
