import { StaticDatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import {
  FETCH_REQUEST,
  FETCH_ERROR,
  FETCH_SELECTED_CAMPAIGNS_DATA,
  FETCH_FILTERED_DATA_FOR_PERFORMANCE,
  FETCH_DATA_FOR_REPORTING,
  FETCH_DATA_FOR_REPORTING_SKAN,
  FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATED,
  FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATEDV1,
  SET_STATIC_WIDZARDS_DATA,
  FETCH_PERFORMANCE_REQUEST,
  FETCH_WIDZARD_REQUEST,
  FETCH_WIDZARD_REQUEST_ERROR,
  SET_STATIC_WIDZARDS_DATA_SKAN,
  FETCH_REPORTING_REQUEST,
  FETCH_REPORTING_REQUEST_ERROR,
  APPLY_FILTER,
  FETCH_PERFORMANCE_REQUEST_ERROR,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_REQUEST_ERROR,
  FETCHED_EVENT_DATA,
  FETCH_ALL_DASHBORD_DATA,
  RESET_VIEW_DATA
} from './campaignTypes';
import BASE_URL from '../config';
axios.defaults.timeout = 120000; // time in ms to handle logout session
const timeout = 120000; // time in ms to handle logout session
export const fetchRequest = (msg) => {
  return {
    type: FETCH_REQUEST,
    payload: []
  };
};
export const fetchPerformanceRequest = (msg) => {
  return {
    type: FETCH_PERFORMANCE_REQUEST,
    payload: []
  };
};
export const fetchEventReportRequest = (msg) => {
  return {
    type: FETCH_EVENT_REQUEST,
    payload: []
  };
};
export const fetchPerformanceRequestError = (error) => {
  return {
    type: FETCH_PERFORMANCE_REQUEST_ERROR,
    payload: error
  };
};
export const fetchReportingRequestError = (error) => {
  return {
    type: FETCH_REPORTING_REQUEST_ERROR,
    payload: error
  };
};
export const fetchEventReportRequestError = (error) => {
  return {
    type: FETCH_EVENT_REQUEST_ERROR,
    payload: error
  };
};
export const fetchWidzardRequest = (msg) => {
  return {
    type: FETCH_WIDZARD_REQUEST,
    payload: msg
  };
};
export const fetchWidzardRequestError = (msg) => {
  return {
    type: FETCH_WIDZARD_REQUEST_ERROR,
    payload: msg
  };
};
export const fetchReportRequest = (msg) => {
  return {
    type: FETCH_REPORTING_REQUEST,
    payload: msg
  };
};
export const fetchFailure = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const resetScreenData = () => {
  return {
    type: RESET_VIEW_DATA,
    payload: []
  };
};
export const fetchCampaignsReporting = (advertiser_name, campaign_list) => {
  let campaignIds = campaign_list.map((item) => item.campaignGuid);
  //console.log(campaignIds)
  let campaignDetailsForAdvertiser = nonskandata.filter(
    (data) => data.AdvertiserName === advertiser_name && campaignIds.includes(data.CampaignID)
  );
  //console.log("campaignDetailsForAdvertiser",campaignDetailsForAdvertiser)
  const result = {
    Impressions: 0,
    Clicks: 0,
    MediaCost: 0
  };

  campaignDetailsForAdvertiser.forEach((entry) => {
    result.Impressions += parseInt(entry.Impressions, 10);
    result.Clicks += parseInt(entry.Clicks, 10);
    result.MediaCost += parseInt(entry.MediaCost, 10);
  });
  //console.log(result)
  return {
    type: FETCH_SELECTED_CAMPAIGNS_DATA,
    payload: {
      campaignDetailsForAdvertiser: campaignDetailsForAdvertiser,
      impressions: result.Impressions,
      clicks: result.Clicks,
      mediaCost: result.MediaCost
    }
  };
};
function filterData(data, filterConditions) {
  // Define the filter function
  const filterFunction = (item) => {
    // Check each condition in the filterConditions array
    return filterConditions.some(([key, condition]) => condition(item[key]));
  };

  // Use the filter function to filter the data array
  return data.filter(filterFunction);
}
function filterDataByKey(data, key, condition) {
  return data.filter((item) => item[key] == condition);
}

// USing this to fetch Performance data from API
export const fetchCampaignReportsData = (metrics, measures) => (dispatch) => {
  //console.log("fetchCampaignReportsData")

  axios({
    method: 'post',
    url: `${BASE_URL}/getCampaignsReport`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: 'dipankar.ghosh@inmobi.com'
    },
    timeout: timeout
  }).then((response) => {
    // //console.log(response.data)
    if (response.data.length > 0) {
      return dispatch(setFetchedCampaignData(metrics, measures, response.data));
    } else {
      return dispatch(fetchFailure);
    }
  });
};
export const setFetchedCampaignData = (metrics, measures, data) => {
  // //console.log("setFetchedCampaignData",data)
  let matricksData = [];
  metrics = 'campaign_name';
  //console.log("metrics,measures",metrics,measures)
  if (metrics == 'campaign_name') {
    matricksData = [...new Set(data.map((entry) => entry.campaign_name))];
  }
  //console.log(matricksData)
  let dataNew = [];
  matricksData.forEach(function (x) {
    let measureData;
    let sortedData, campaignDetailsForAdvertiser1, filteredData;
    switch (measures) {
      case 'Clicks':
        filteredData = filterDataByKey(nonskandata, metrics, x);

        sortedData = filteredData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
        measureData = sortedData.map((entry) => entry.clicks);
        ////console.log("measureData==>",measureData)
        break;

      default:
        measureData = [];
    }
    let campaignDetails = {
      name: x,
      data: measureData
    };
    ////console.log("campaignDetails*****",campaignDetails);
    dataNew.push(campaignDetails);
    //console.log(dataNew)
  });

  return {
    type: FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATEDV1,
    payload: data
  };
};

export const fetchCampaignReport = (metrics, measures) => {
  let campaign_list = ['a299c81632a634616fc1df15cc4a4b92', '240f30e886f24154b4af301ad28172d3', 'a299c81632a634616fc1df15cc4a4b34'];
  let campaignDetailsForAdvertiser = nonskandata.filter((data) => campaign_list.includes(data.CampaignID));
  let dates = [...new Set(campaignDetailsForAdvertiser.map((entry) => entry.Date))];
  //console.log("dates....",dates)
  let dataNew = [];
  ////console.log("campaign_list***",campaign_list)
  campaign_list.forEach(function (x) {
    ////console.log("CampaignID===>",x)
    campaignDetailsForAdvertiser = nonskandata.filter((data) => data.CampaignID == x);
    const sortedData = campaignDetailsForAdvertiser.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    const impressionsArray = sortedData.map((entry) => entry.Impressions);
    ////console.log(impressionsArray);
    let campaignDetails = {
      name: x,
      data: impressionsArray
    };
    ////console.log("campaignDetails---->",campaignDetails)
    dataNew.push(campaignDetails);
  });
  const result = {
    seriesData: dataNew,
    date: dates
  };
  return {
    type: FETCH_FILTERED_DATA_FOR_PERFORMANCE,
    payload: result
  };
};
function sumUpmeasureData(measureData) {
  ////console.log("measureData")
  return measureData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}
// Widget and bar graph function in current use for Bar
export const fetchCampaignGraphsDataPulled = (metrics, measures, fetchedData, sfId) => {
  // console.log('fetchCampaignGraphsDataPulled');
  const result = {
    seriesData: [],
    date: [],
    campaignMainData: fetchedData
  };
  ////console.log("result",result)
  return {
    type: FETCH_FILTERED_DATA_FOR_PERFORMANCE_UPDATED,
    payload: result
  };
};
// Widget and bar graph function in current use for Bar

export const fetchCampaignGraphsData = (metrics, measures) => (dispatch, getState) => {
  let advertiser_guid = getState().advertiserReducer.advertiser_guid;
  let campaign_list = getState().advertiserReducer.selected_campaigns;
  dispatch(fetchPerformanceRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getReportingDataForWidgets`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      ////console.log(response.data)
      if (response.data.length > 0) {
        return dispatch(fetchCampaignGraphsDataPulled(metrics, measures, response.data));
      } else {
        return dispatch(fetchPerformanceRequestError('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchPerformanceRequestError('Not able to fetch data. Please try again'));
      //return Promise.reject(error)
    });
};

export const fetchCampaignGraphsDataSkan = (metrics, measures) => (dispatch, getState) => {
  let advertiser_guid = getState().advertiserReducer.advertiser_guid;
  let campaign_list = getState().advertiserReducer.selected_campaigns;
  dispatch(fetchPerformanceRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getReportingDataForWidgetsSkan`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      CampaignIds: campaign_list,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      // console.log(
      //   "fetchCampaignGraphsDataSkan",
      //   response.data.data,
      //   response.data.data.length,
      // );
      if (response.data.data.length > 0) {
        response.data = response.data.data;
        return dispatch(fetchCampaignGraphsDataPulled(metrics, measures, response.data));
      } else {
        return dispatch(fetchPerformanceRequestError('Sorry no data found'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchPerformanceRequestError('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
function detectKeys(jsonData) {
  const keys = new Set();

  jsonData.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      keys.add(key);
    });
  });

  return Array.from(keys);
}

// Get NonSkan Data
export const fetchReportDataMinified = (campaignList) => (dispatch, getState) => {
  //console.log("fetchReportDataMinified", campaignList);
  dispatch(fetchReportRequest());
  //let campaign_list=getState().advertiserReducer.selected_campaigns
  //onsole.log(campaignList)
  axios({
    method: 'post',
    url: `${BASE_URL}/getCampaignsReportNonSkan`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: 'dipankar.ghosh@inmobi.com',
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      // //console.log(response.data)
      if (response.data.length > 0) {
        return dispatch(fetchDataForReportingDatatable(response.data));
      } else {
        return dispatch(fetchFailure);
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
//Get Skan Data
export const fetchReportDataMinifiedForSkan = (campaignList) => (dispatch, getState) => {
  //console.log("process",process.env)
  dispatch(fetchReportRequest());
  //let campaign_list=getState().advertiserReducer.selected_campaigns
  //onsole.log(campaignList)
  axios({
    method: 'post',
    url: `${BASE_URL}/getCampaignsReportSkan`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: 'dipankar.ghosh@inmobi.com',
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      // console.log("skan report", response.data);
      if (response.data.length > 0) {
        return dispatch(fetchDataForReportingDatatable(response.data));
      } else {
        return dispatch(fetchFailure);
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
export const fetchDataForReportingDatatable = (data) => {
  // Call the function with your JSON data
  const detectedKeys = detectKeys(data);
  ////console.log("detectedKeys",detectedKeys)
  const campaignDetails = data.map((item, id = index) => ({ id, ...item }));
  ////console.log(campaignDetails)
  return {
    type: FETCH_DATA_FOR_REPORTING,
    payload: campaignDetails
  };
};
export const fetchDataForEventData = (data) => {
  //console.log(data);
  // Call the function with your JSON data
  const detectedKeys = detectKeys(data);
  ////console.log("detectedKeys",detectedKeys)
  const campaignDetails = data.map((item, id = index) => ({ id, ...item }));
  //console.log(campaignDetails);
  return {
    type: FETCHED_EVENT_DATA,
    payload: campaignDetails
  };
};
export const fetchDashboardNonSkanCopleteData = (data) => {
  // Call the function with your JSON data
  const detectedKeys = detectKeys(data);
  ////console.log("detectedKeys",detectedKeys)
  //const campaignDetails = data.map((item, id = index) => ({ id, ...item }));
  //console.log(data[0]);
  return {
    type: FETCH_ALL_DASHBORD_DATA,
    payload: data
  };
};

export const fetchDataForReportingDatatableSkan = (advertiser_name, advertiser_guid) => {
  // let campaignDetailsForAdvertiser=skandata.filter(data=>PublisherAppBundle.includes(data.PublisherAppBundle) );
  let campaignDetailsForAdvertiser = skandata;
  let p = Object.keys(campaignDetailsForAdvertiser);
  ////console.log(campaignDetailsForAdvertiser)

  // Call the function with your JSON data
  const detectedKeys = detectKeys(campaignDetailsForAdvertiser);
  ////console.log("detectedKeys",detectedKeys)
  const campaignDetailsForAdvertiserWithIndex = campaignDetailsForAdvertiser.map((item, id = index) => ({ id, ...item }));
  ////console.log(campaignDetailsForAdvertiserWithIndex)
  return {
    type: FETCH_DATA_FOR_REPORTING_SKAN,
    payload: campaignDetailsForAdvertiserWithIndex
  };
};

export const getStaticWidgetsData = (campaign_guids) => (dispatch, getState) => {
  // console.log(
  //   "start_date",
  //   getState().campaignReducer.start_date,
  //   campaign_guids,
  // );
  dispatch(fetchWidzardRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getWidgetsDataByCampaignGuids`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      advertiser_guid: localStorage.getItem('advertiserId'),
      campaign_guids: campaign_guids,
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log(response.data.data);
      let data = {
        totalCalculatedData: response.data.data
      };
      dispatch(fetchedWidzardData(data));
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};

export const getStaticWidgetsDataForSkan = (campaign_guids) => (dispatch, getState) => {
  // console.log(
  //   "start_date",
  //   getState().campaignReducer.start_date,
  //   campaign_guids,
  // );
  dispatch(fetchWidzardRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getWidgetsDataByCampaignGuidsForSkan`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      advertiser_guid: localStorage.getItem('advertiserId'),
      campaign_guids: campaign_guids,
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    }
  })
    .then((response) => {
      let data = {
        totalCalculatedData: response.data.data
      };
      dispatch(fetchedWidzardDataSkan(data));
    })
    .catch(function (error) {
      //console.log("Show error notification!", error, error.response);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
export const fetchedWidzardData = (data) => {
  //console.log(data[0]);
  return {
    type: SET_STATIC_WIDZARDS_DATA,
    payload: data[0]
  };
};
export const fetchedWidzardDataSkan = (data) => {
  //console.log(data[0]);
  // let timeZone=getState().advertiserReducer.timeZone;
  // if(timeZone!="JST"){
  //   data[0].media_cost_local_currency=0
  // }
  // console.log(data[0]);
  return {
    type: SET_STATIC_WIDZARDS_DATA_SKAN,
    payload: data[0]
  };
};
export const applyFilter = (status) => {
  // console.log(status);
  return {
    type: APPLY_FILTER,
    payload: status
  };
};

export const fetchEventDataForNonSkan = (campaignList) => (dispatch, getState) => {
  //console.log('fetchReportDataMinified', campaignList);
  dispatch(fetchEventReportRequest());
  //let campaign_list=getState().advertiserReducer.selected_campaigns
  //onsole.log(campaignList)
  axios({
    method: 'post',
    url: `${BASE_URL}/getEventsReportNonSkan`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: 'dipankar.ghosh@inmobi.com',
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      // //console.log(response.data)
      if (response.data.length > 0) {
        return dispatch(fetchDataForEventData(response.data));
      } else {
        return dispatch(fetchEventReportRequestError);
      }
    })
    .catch(function (error) {
      //console.log('Show error notification!', error, error.response);
      return dispatch(fetchEventReportRequestError('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
export const fetchEventDataForSkan = (campaignList) => (dispatch, getState) => {
  //console.log("process",process.env)
  dispatch(fetchEventReportRequest());

  axios({
    method: 'post',
    url: `${BASE_URL}/getEventsReportSkan`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: 'dipankar.ghosh@inmobi.com',
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date
    },
    timeout: timeout
  })
    .then((response) => {
      if (response.data.length > 0) {
        return dispatch(fetchDataForEventData(response.data));
      } else {
        return dispatch(fetchEventReportRequestError);
      }
    })
    .catch(function (error) {
      //console.log('Show error notification!', error, error.response);
      return dispatch(fetchEventReportRequestError('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};

export const fetchDashboardDataNonSkan = (campaignList) => (dispatch, getState) => {
  //console.log("process",process.env)
  //dispatch(fetchEventReportRequest());
  dispatch(fetchReportRequest());
  dispatch(fetchEventReportRequest());
  dispatch(fetchPerformanceRequest());
  dispatch(fetchWidzardRequest());
  //console.log('timezone-->', getState().advertiserReducer.timeZone);
  axios({
    method: 'post',
    url: `${BASE_URL}/getNonSkanAllData`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      if (response.data.length > 0) {
        //console.log(response.data);
        dispatch(fetchDataForReportingDatatable(response.data[0].overview));
        dispatch(fetchDataForEventData(response.data[0].events));
        dispatch(fetchCampaignGraphsDataPulled([], [], response.data[0].charts));
        dispatch(fetchedWidzardData(response.data[0].widgets));
        return dispatch(fetchDashboardNonSkanCopleteData(response.data));
      } else {
        return dispatch(fetchEventReportRequestError);
      }
    })
    .catch(function (error) {
      console.log('Show error notification!', error, error.response);
      if (error.response.data.statusCode == 404) {
        //console.log('Wizard error');
        dispatch(fetchWidzardRequestError('No Data Found'));
        dispatch(fetchPerformanceRequestError('No data found'));
        dispatch(fetchReportingRequestError('No Data Found'));
        dispatch(fetchEventReportRequestError('No Data Found'));
      } else {
          dispatch(fetchWidzardRequestError('Not able to fetch data. Please retry'));
          dispatch(fetchPerformanceRequestError('Not able to fetch data. Please retry'));
          dispatch(fetchReportingRequestError('Not able to fetch data. Please retry'));
        return dispatch(fetchEventReportRequestError('Not able to fetch data. Please retry'));
      }
      //return Promise.reject(error)
    });
};
export const fetchDashboardDataSkan = (campaignList) => (dispatch, getState) => {
  //console.log("process",process.env)
  //dispatch(fetchEventReportRequest());
  dispatch(fetchReportRequest());
  dispatch(fetchEventReportRequest());
  dispatch(fetchPerformanceRequest());
  dispatch(fetchWidzardRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getSkanAllData`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      if (response.data.length > 0) {
        //console.log(response.data);
        dispatch(fetchDataForReportingDatatable(response.data[0].overview));
        dispatch(fetchDataForEventData(response.data[0].events));
        dispatch(fetchCampaignGraphsDataPulled([], [], response.data[0].charts));
        if (getState().advertiserReducer.timeZone == 'JST') {
          dispatch(fetchedWidzardDataSkan(response.data[0].widgets));
        } else {
          let widgetData = response.data[0].widgets;
          let chartData = response.data[0].charts;
          widgetData[0].media_cost_local_currency = 0;
          chartData[0].media_cost_local_currency = 0;
          // console.log('widgetData', widgetData);
          dispatch(fetchedWidzardDataSkan(response.data[0].widgets));
          dispatch(fetchCampaignGraphsDataPulled([], [], response.data[0].charts));
        }

        //return dispatch(fetchDashboardNonSkanCopleteData(response.data));
      } else {
        return dispatch(fetchEventReportRequestError);
      }
    })
    .catch(function (error) {
      console.log('Show error notification!', error, error.response);
      //console.log('>>>>>>>>>>>>', error.response.data.statusCode);
      if (error.response.data.statusCode == 404) {
        console.log('Wizard error');
        dispatch(fetchWidzardRequestError('No Data Found'));
        dispatch(fetchPerformanceRequestError('No data found'));
        dispatch(fetchReportingRequestError('No Data Found'));
        dispatch(fetchEventReportRequestError('No Data Found'));
      } else {
        dispatch(fetchWidzardRequestError('Not able to fetch data. Please retry'));
        dispatch(fetchPerformanceRequestError('Not able to fetch data. Please retry'));
        dispatch(fetchReportingRequestError('Not able to fetch data. Please retry'));
        return dispatch(fetchEventReportRequestError('Not able to fetch data. Please retry'));
      }

      //return Promise.reject(error)
    });
};
