import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import AdvertisersFilter from '../../../components/Dashboard/AdvertisersFilter';
//import CampaignAutocompleteDropDown from "../../../components/Dashboard";
// assets
import { IconMenu2 } from '@tabler/icons';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, timeZone }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  let currentPath = location.pathname.split('/')[1];
  console.log(currentPath);
  // let timeZoneLocal = localStorage.getItem("timeZone");
  // useEffect(()=>{

  //   timeZoneLocal = localStorage.getItem("timeZone");
  // },[timeZone])
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase> */}
      </Box>
      <Box sx={{ flexGrow: 2 }}>{currentPath != 'advertisers' ? <AdvertisersFilter /> : ''}</Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 2, marginLeft: '5px', textAlign: 'center' }}>
        <Typography variant="h5" color={theme.palette.primary.dark}>
          {currentPath != 'advertisers' ? `Current Reporting is in ${timeZone} Time Zone` : ''}
        </Typography>
        {/* <CampaignAutocompleteDropDown /> */}
      </Box>
      <Box sx={{ flexGrow: 2 }}></Box>

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};
const mapStateToProps = (state) => {
  return {
    timeZone: state.advertiserReducer.timeZone
  };
};
export default connect(mapStateToProps, null)(Header);
