import { FETCH_RAW_EVENT_REQUEST, FETCH_RAW_EVENT_DATA, FETCH_RAW_EVENT_ERROR, RESET_RAW_EVENT_DATA } from './rawEventTypes';
const initialState = {
  loading: false,
  rawEventData: [],
  error: '',
  error_msg: ''
};

const RawEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RAW_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RESET_RAW_EVENT_DATA:
      return {
        ...state,
        rawEventData: []
      };
    case FETCH_RAW_EVENT_DATA:
      return {
        ...state,
        loading: false,
        rawEventData: action.payload
      };
    case FETCH_RAW_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        rawEventData: [],
        error_msg: 'Sorry no data found',
        error: action.payload
      };
    default:
      return state;
  }
};
export default RawEventReducer;
