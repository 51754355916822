// assets
import { IconDashboard, IconBrandChrome, IconStack, IconReportAnalytics, IconBrandAsana, IconFileTime, IconUserX } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconBrandChrome,
  IconStack,
  IconReportAnalytics,
  IconBrandAsana,
  IconFileTime,
  IconUserX
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
  id: 'admin',
  type: 'group',
  children: [
    {
      id: 'authentication',
      title: 'Admin Panel',
      type: 'collapse',
      icon: icons.IconBrandAsana,
      children: [
        {
          id: 'admin',
          title: 'Onboard User',
          type: 'item',
          url: '/pages/admin/onboarduser',
          icon: icons.IconUserX,
          breadcrumbs: false
        },
        {
          id: 'admin',
          title: 'Time Zone Mapping',
          type: 'item',
          url: '/pages/admin/usertimezone',
          icon: icons.IconFileTime,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default admin;
