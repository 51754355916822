import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MainCard from 'ui-component/cards/MainCard';
import LoaderCircular from 'ui-component/LoaderCircular';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';

const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'advertiser_name',
    headerName: 'Advertiser Name',
    // flex: 1,
    width: 250,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },
  {
    field: 'campaign_name',
    headerName: 'Campaign Name',
    // flex: 1,
    width: 350,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },
  {
    field: 'os_name',
    headerName: 'OS',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  // {
  //     field: "impressions",
  //     headerName: "Impressions",
  //     valueFormatter: (params) => splitDigits(params.value),
  //     flex:1,
  //     align: "center",
  //     headerAlign: "center",
  // },
  {
    field: 'clicks',
    headerName: 'Clicks',
    valueFormatter: (params) => splitDigits(params.value),
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost',
    headerName: 'Media Cost',
    valueFormatter: (params) => '$ ' + splitDigits(roundToTwoDecimal(params.value)),
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost_local_currency',
    headerName: 'Media Cost',
    valueFormatter: (params) => '¥ ' + splitDigits(roundYen(params.value)),
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'events',
    headerName: 'Events',
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'installs',
    headerName: 'Installs',
    valueFormatter: (params) => splitDigits(params.value),
    flex: 1,
    align: 'center',
    headerAlign: 'center'
  }
];
function splitDigits(num) {
  return Number(num).toLocaleString();
}
function roundToTwoDecimal(value) {
  if (value == '-999') return '-';
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
function roundYen(value) {
  if (value == '-999') return '-';
  return Math.round(value);
}

const IapDataView = ({ loading, iapData, error, timeZone, isDollar }) => {
  //console.log('iapData', iapData, iapData.length, loading, 'isDollar', isDollar, error);
  const columnVisibilityModel = React.useMemo(() => {
    if (isDollar == true) {
      return {
        media_cost_local_currency: false,
        media_cost: true
      };
    } else {
      return {
        media_cost_local_currency: true,
        media_cost: false
      };
    }
  }, [timeZone, isDollar]);
  return (
    <>
      {loading ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">IAP Overview Report</Typography>
            </Grid>
            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <div style={{ height: '200px', width: '100%' }}>
                {' '}
                <LoaderCircular show={true} />
              </div>
            </Grid>
          </Grid>
        </MainCard>
      ) : iapData.length == 0 ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">IAP Overview Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography xs={{ textAlign: 'center' }}>
                {/* Please click <b>Apply Filter</b> to view data */}
                {error.length != 0 ? (
                  'No Data Found'
                ) : (
                  <span>
                    Please click <b>Apply Filter</b> to view data
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">IAP Overview Report</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <Box>
                    <div style={{ minHeight: '100%', width: '100%' }}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            position: 'relative',
                            top: '50%',
                            left: '50%'
                          }}
                        />
                      ) : (
                        <DataGrid
                          rows={iapData}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10
                              }
                            }
                          }}
                          components={{
                            LoadingOverlay: CircularProgress
                          }}
                          loading={loading}
                          pageSizeOptions={[10, 20, 30]}
                          slots={{ toolbar: GridToolbar }}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableDensitySelector
                          columnVisibilityModel={columnVisibilityModel}
                          style={{ minHeight: '100%', width: '100%' }}
                          autoHeight
                          //getRowHeight={getRowHeight}
                          getRowHeight={() => 'auto'}
                          className="custom-datagrid"
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.IAPReducer.loading,
    iapData: state.IAPReducer.iapData,
    error: state.IAPReducer.error,
    isDollar: state.campaignReducer.isDollar
  };
};
export default connect(mapStateToProps, null)(IapDataView);
