import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography, CardContent, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import LoaderCircular from 'ui-component/LoaderCircular';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import '../Dashboard/styleSearch.scss';
const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'campaign_name',
    headerName: 'Campaign Name',
    width: 350,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },
  // {
  //   field: "event_name",
  //   headerName: "Event Name",
  // },
  {
    field: 'ad_name',
    headerName: 'Creative Name',
    // flex:2,
    width: 250,
    renderCell: (params) => <div className="campaign_name_settings">{params.value}</div>
  },
  {
    field: 'creative_type',
    headerName: 'Creative Type',
    width: 110,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'country',
    headerName: 'Country',
    // flex: 1.5,
    width: 110,
    // align: 'center',
    // headerAlign: 'center'
  },
  {
    field: 'os_name',
    headerName: 'OS',
    // flex:0.8,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    valueFormatter: (params) => splitDigits(params.value),
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    valueFormatter: (params) => splitDigits(params.value),
    // flex:1,
    width: 130,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost',
    headerName: 'Media Cost',
    valueFormatter: (params) => '$ ' + splitDigits(roundToTwoDecimal(params.value)),
    // flex:1,
    width: 130,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'media_cost_local_currency',
    headerName: 'Media Cost',
    valueFormatter: (params) => '¥ ' + splitDigits(roundYen(params.value)),
    align: 'center',
    headerAlign: 'center',
    width: 130
    // flex:1
  },
  {
    field: 'installs',
    headerName: 'Installs',
    sort: 'desc',
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'kpi_event_count',
    headerName: 'Events',
    sort: 'desc',
    // flex:1,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ctr',
    headerName: 'CTR',
    valueFormatter: (params) => roundToTwoDecimalPercent(params.value),
    // flex:0.8,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ecpe',
    headerName: 'eCPE',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    // flex:0.8,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ecpm',
    headerName: 'eCPM',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    // flex:0.8,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ecpc',
    headerName: 'eCPC',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    // flex:0.8,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'ecpi',
    headerName: 'eCPI',
    valueFormatter: (params) => roundToTwoDecimal(params.value),
    // flex:0.8,
    width: 100,
    align: 'center',
    headerAlign: 'center'
  }
  // {
  //   field: 'Country',
  //   headerName: 'Country',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];
function splitDigits(num) {
  return Number(num).toLocaleString();
}
function roundToTwoDecimal(value) {
  if (value == '-999') return '-';
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
function roundYen(value) {
  if (value == '-999') return '-';
  return Math.round(value);
}
function roundToTwoDecimalPercent(value) {
  if (value == '-999') return '-';
  const roundedValue = Math.round((value * 100 + Number.EPSILON) * 100) / 100; // Multiply by 100 to convert to percentage
  return `${roundedValue}%`; // Append '%' sign
}
const CreativeDataNonSkanView = ({ creativeData, loading, campaign_list, isSkan, end_date, start_date, error, timeZone, isDollar }) => {
  const location = useLocation();
  let currentPath = location.pathname.split('/')[1];
  //console.log(currentPath);
  useEffect(() => {
    //fetchDataForReportingDatatable();
    // fetchReportData()
    if (campaign_list.length > 0) {
      //console.log(campaign_list)
      //let campaignIds=campaign_list.map(value=>value.campaignGuid)
      //fetchReportData(campaign_list)
    }
  }, [campaign_list, isSkan, end_date, start_date]);

  //console.log(creativeData.length);
  const getRowHeight = (params) => {
    const column2Value = params.model.campaign_name;
    //console.log(column2Value);
    if (column2Value.length > 110) {
      return 150; // Return the desired row height in pixels
    } else if (column2Value.length > 100) {
      return 130; // Return the desired row height in pixels
    } else {
      return 50;
    }
  };
  const columnVisibilityModel = React.useMemo(() => {
    if (timeZone == 'JST' && isDollar == true) {
      return {
        media_cost_local_currency: false,
        media_cost: true
      };
    } else if (timeZone == 'JST' && isDollar == false) {
      return {
        media_cost_local_currency: true,
        media_cost: false
      };
    } else {
      return {
        media_cost_local_currency: false,
        media_cost: true
      };
    }
  }, [timeZone, isDollar]);
  //console.log('cc', campaign_list, loading);
  return (
    <>
      {loading ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Creative Level Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <div style={{ height: '200px', width: '100%' }}>
                {' '}
                <LoaderCircular show={true} />
              </div>
            </Grid>
          </Grid>
        </MainCard>
      ) : creativeData == 0 || campaign_list.length == 0 ? (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Creative Level Report</Typography>
            </Grid>

            <Grid item xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography style={{ textAlign: 'center' }}>
                {/* Please click <b>Apply Filter</b> to view data */}
                {error.length != 0 ? (
                  'No Data Found'
                ) : (
                  <span>
                    Please click <b>Apply Filter</b> to view data
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Creative Level Report</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <Box>
                    <div style={{ minHeight: '400px', width: '100%' }}>
                      {loading ? (
                        <CircularProgress
                          style={{
                            position: 'relative',
                            top: '50%',
                            left: '50%'
                          }}
                        />
                      ) : (
                        <DataGrid
                          rows={creativeData}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10
                              }
                            },
                            sorting: {
                              sortModel: [{ field: 'media_cost', sort: 'desc' }] // Default sort on 'age' column
                            }
                          }}
                          components={{
                            LoadingOverlay: CircularProgress
                          }}
                          loading={loading}
                          pageSizeOptions={[10, 20, 30]}
                          slots={{ toolbar: GridToolbar }}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableDensitySelector
                          // style={{ minHeight: "400px", width: "100%" }}
                          autoHeight
                          columnVisibilityModel={columnVisibilityModel}
                          //getRowHeight={getRowHeight}
                          getRowHeight={() => 'auto'}
                          className="custom-datagrid"
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    creativeData: state.creativeReducer.creativeData,
    loading: state.creativeReducer.creativeLoading,
    campaign_list: state.advertiserReducer.selected_campaigns,
    isSkan: state.campaignReducer.isSkan,
    end_date: state.campaignReducer.end_date,
    start_date: state.campaignReducer.start_date,
    error: state.creativeReducer.error,
    timeZone: state.advertiserReducer.timeZone,
    isDollar: state.campaignReducer.isDollar
  };
};

export default connect(mapStateToProps, null)(CreativeDataNonSkanView);
