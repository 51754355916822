import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Table, TableBody, TableCell, TableContainer, Grid, Button, Modal, Box } from '@mui/material';
import EditUserMappingDetails from './EditUserMappingDetails';
import OnboardNewUser from './OnboardNewUser';
import { fetchAllDashboardUserList } from '../../redux/user/userActions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
};

const UserList = ({ fetchAllDashboardUserList, dashboardUserList }) => {
  const [open, setOpen] = React.useState(false);
  const [openNewForm, setOpenNewForm] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState();
  console.log(dashboardUserList);
  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },

    {
      field: 'email_id',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'role',
      headerName: 'Role',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button variant="contained" color="primary" size="small" style={{ marginRight: 16 }} onClick={() => handleOpen(params)}>
            Edit
          </Button>
        </div>
      )
    }
  ];
  const dashboard_user_list = [
    {
      name: 'paul.yang',
      email: 'paul.yang@mattel163.com',
      status: 'ACTIVE',
      type: 'internal'
    },
    {
      name: 'debaraj.dey',
      email: 'debaraj.dey@inmobi.com',
      status: 'ACTIVE',
      type: 'admin'
    }
  ];
  const handleOpen = (params) => {
    console.log(params.row);
    setUserDetails(params.row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnboardNewUserClose = () => {
    setOpenNewForm(false);
  };
  const handleOpenOnboardNewUser = () => {
    setOpenNewForm(true);
  };
  useEffect(() => {
    fetchAllDashboardUserList();
  }, []);
  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} align="right">
          <Button type="submit" variant="outlined" color="primary" onClick={handleOpenOnboardNewUser}>
            Add New User
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <DataGrid
              rows={dashboardUserList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              pageSizeOptions={[10, 20, 30]}
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              style={{ minHeight: '400px', width: '100%' }}
              autoHeight
              className="custom-datagrid"
              getRowId={(row) => row.email_id}
            />
          </Grid>
        </Grid>
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style, width: 500 }}>
            <div>
              <EditUserMappingDetails details={userDetails} />
            </div>
          </Box>
        </Modal>
        <Modal open={openNewForm} onClose={handleOnboardNewUserClose}>
          <Box sx={{ ...style, width: 500 }}>
            <div>
              <OnboardNewUser />
            </div>
          </Box>
        </Modal>
      </Grid>
    </MainCard>
  );
};
const mapStateToProps = (state) => {
  return {
    dashboardUserList: state.userReducer.dashboardUserList
  };
};
const mapDispatchToProps = {
  fetchAllDashboardUserList: fetchAllDashboardUserList
};
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
