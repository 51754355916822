import React, { useState, useEffect } from 'react';
import { gridSpacing } from 'store/constant';
import { connect } from 'react-redux';
import {
  Grid,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Tab,
  CustomTabPanel,
  Chip,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { fetchOrgList } from '../../redux/advertiser/advertiserActions';
import { fetchAdvertisersListForUserBasedOnEmailId, fetchAdvertiserListForProvidedEmail } from '../../redux/user/userActions';
import { fetchAdvertiserList } from '../../redux/advertiser/advertiserActions';
const OnboardNewUser = ({
  fetchOrgList,
  org_list,
  fetchAdvertisersListForUserBasedOnEmailId,
  advertisersForUserTimeZone,
  fetchAdvertiserList,
  user_type,
  fetchAdvertiserListForProvidedEmail
}) => {
  console.log('advertisersForUserTimeZone', advertisersForUserTimeZone, user_type);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    advertiserList: [],
    advertiserName: '',
    advertiserGuid: '',
    userType: user_type,
    region: org_list
  });
  const [value, setValue] = React.useState('1');
  const [error, setError] = useState('');
  const [utcValue, setUTCValue] = useState([]);
  const [jstValue, setJSTValue] = useState([]);
  const [org_default_selected, setOrg_default_selected] = useState([]);
  const [org_list_local, setOrg_list_local] = useState(org_list);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [userType, setUserType] = useState(user_type);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value
    });
    setUserType(value);
  };
  //const filteredData = response.data.data.filter(item => !uniqueOrgNames.includes(item.org_name));
  const handleEmailChange = (e) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      email: value
    });

    if (!value) {
      setError('Email is required');
    } else if (!validateEmail(value)) {
      setError('Invalid email address');
    } else {
      setError('');
    }
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleInputChange = (event, value) => {
    console.log(value);
    console.log(value.map((item) => item.org_name));

    setOrg_default_selected(value);
    setFormData({
      ...formData,
      region: value.map((item) => item.org_name)
    });
  };
  const handleJSTInputChange = (event, value) => {
    console.log(value);
    const updatedJSTData = value.map((advertiser) => ({
      ...advertiser,
      timezone: 'JST'
    }));
    console.log(updatedJSTData);
    setJSTValue(updatedJSTData);
    // setFormData({
    //     ...formData,
    //     'region': value.map(item=>item.org_name)
    //   });
  };
  const handleUTCInputChange = (event, value) => {
    console.log(value);
    const updatedUTCData = value.map((advertiser) => ({
      ...advertiser,
      timezone: 'UTC'
    }));
    setUTCValue(updatedUTCData);
  };

  const fetchUserAdvertiserMappedDetails = (e) => {
    console.log(formData.email);
    setFormData({
      ...formData,
      email: formData.email
    });
    fetchAdvertisersListForUserBasedOnEmailId(formData.email);
    // To fetch user role based on email id and associated advertisers associated
    fetchAdvertiserListForProvidedEmail(formData.email);
  };
  // Save user advertiser mapping for both UTC and JST
  const saveUserSettings = () => {
    console.log('inside save container');
    console.log(formData);
    let details_to_send = '';
    if (formData.userType == 'admin') {
      details_to_send = {
        email_id: formData.email,
        user_type: formData.userType,
        org_utc: formData.region,
        advertisers_non_utc: jstValue,
        advertisers_utc: utcValue
      };
    }
    console.log(details_to_send);
  };
  useEffect(() => {
    // if(formData.email!=''){
    fetchOrgList();
    // }
  }, []);
  useEffect(() => {
    console.log(user_type);
    setFormData({
      ...formData,
      userType: user_type
    });
  }, [user_type]);
  useEffect(() => {
    console.log(org_list);
  }, [org_list]);
  useEffect(() => {
    console.log('advertisersForUserTimeZone', advertisersForUserTimeZone);
    if (advertisersForUserTimeZone.orgNames.length != 0) {
      let selectedOrgList = org_list.filter((item) => advertisersForUserTimeZone.orgNames.includes(item.org_name));
      console.log(selectedOrgList);
      setOrg_default_selected(selectedOrgList);
      setFormData({
        ...formData,
        region: selectedOrgList.map((item) => item.org_name)
      });
    } else {
      setOrg_default_selected([]);
    }
    if (advertisersForUserTimeZone.jstAdvertisers.length != 0) {
      let selectedJSTAdvertiserList = advertisersForUserTimeZone.jstAdvertisers.filter(
        (item) => item.selected == 'true' && item.timezone == 'JST'
      );
      console.log(selectedJSTAdvertiserList);
      setJSTValue(selectedJSTAdvertiserList);
      // setFormData({
      //     ...formData,
      //     'region': value.map(item=>item.org_name)
      //   });
    }
  }, [advertisersForUserTimeZone]);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={12} md={12} sm={12}>
        <Typography id="modal-title" variant="h3" component="h2" align="center">
          Onboard New User Details
        </Typography>
        <Divider />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <FormLabel id="demo-radio-buttons-group-label">Name</FormLabel>
        <TextField autoFocus margin="dense" name="name" type="text" fullWidth value={formData.name} onChange={handleChange} />
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <FormLabel id="demo-radio-buttons-group-label">Email Address</FormLabel>
        <TextField
          autoFocus
          margin="dense"
          name="email"
          type="email"
          fullWidth
          value={formData.email}
          onChange={handleEmailChange}
          error={error}
          helperText={error ? error : ' '}
        />
        {error || formData.email.length == 0 ? (
          ''
        ) : (
          <Button type="submit" variant="outlined" color="primary" onClick={fetchUserAdvertiserMappedDetails}>
            Fetch Details
          </Button>
        )}
        {/* {error && <span style={{ color: 'red' }}>{error}</span>} */}
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <FormLabel id="demo-radio-buttons-group-label">User Role</FormLabel>
        <RadioGroup
          row
          aria-labelledby="userType"
          label="Role"
          name="userType"
          value={formData.userType}
          defaultValue={userType}
          onChange={handleChange}
        >
          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
          <FormControlLabel value="internal" control={<Radio />} label="Internal" />
          <FormControlLabel value="client" control={<Radio />} label="Client" />
        </RadioGroup>
      </Grid>

      {userType != 'client' && formData.userType != 'client' ? (
        <Grid item lg={12} md={12} sm={12}>
          <FormLabel id="demo-radio-buttons-group-label">Region</FormLabel>
          <Autocomplete
            multiple
            options={org_list_local}
            value={org_default_selected}
            onChange={handleInputChange}
            getOptionLabel={(option) => option.org_name}
            renderInput={(params) => <TextField {...params} placeholder="Select Region" />}
          />
        </Grid>
      ) : (
        ''
      )}
      <Grid item lg={12} md={12} sm={12}>
        <TabContext value={value} sx={{ border: 1, borderColor: 'divider' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="UTC Advertisers" value="1" />
              <Tab label="JST Advertisers" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Autocomplete
                  multiple
                  options={advertisersForUserTimeZone.utcAdvertisers}
                  onChange={handleUTCInputChange}
                  value={utcValue}
                  getOptionLabel={(option) => option.advertiser_name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={option.selected == 'true' && option.timezone == 'UTC'}
                        onChange={(e) => {
                          option.selected = e.target.checked;
                          handleUTCInputChange(null, value);
                        }}
                      />
                      {option.advertiser_name}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} placeholder="Select Advertiser" />}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Autocomplete
              multiple
              options={advertisersForUserTimeZone.jstAdvertisers}
              onChange={handleJSTInputChange}
              value={jstValue}
              getOptionLabel={(option) => option.advertiser_name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={option.selected == 'true' && option.timezone == 'JST'}
                    onChange={(e) => {
                      option.selected = e.target.checked;
                      handleJSTInputChange(null, value);
                    }}
                  />
                  {option.advertiser_name}
                </li>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Select Advertiser" />}
            />
          </TabPanel>
        </TabContext>
      </Grid>
      <Grid item lg={12} md={12} sm={12} align="right">
        <Button type="submit" variant="outlined" color="primary" onClick={saveUserSettings}>
          Save Details
        </Button>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    org_list: state.advertiserReducer.org_list,
    advertisersForUserTimeZone: state.userReducer.advertisersForUserTimeZone,
    user_type: state.userReducer.fetchedUserRoleType
  };
};
const mapDispatchToProps = {
  fetchOrgList: fetchOrgList,
  fetchAdvertisersListForUserBasedOnEmailId: fetchAdvertisersListForUserBasedOnEmailId,
  fetchAdvertiserList: fetchAdvertiserList,
  fetchAdvertiserListForProvidedEmail: fetchAdvertiserListForProvidedEmail
};
export default connect(mapStateToProps, mapDispatchToProps)(OnboardNewUser);
