import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import LoaderCircular from 'ui-component/LoaderCircular';
import Chart from 'react-apexcharts';
import '../Dashboard/styleSearch.scss';
import { gridSpacing } from 'store/constant';

const metricsLabels = [
  { value: 'clicks', label: 'Clicks' },
  { value: 'impressions', label: 'Impressions' },
  { value: 'installs', label: 'Installs' },
  { value: 'kpi_event_count', label: 'KPI Event Count' }
];

function formatNumberAbbreviated(value, args = 1) {
  if (value == 0) return 0;
  const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
  if (!value || Number.isNaN(value)) return 0;
  if (value < 1000) return value;

  const exp = Math.floor(Math.log(value) / Math.log(1000));
  return (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
}

const WowNonSkanView = ({ wowReportData, loading, error, timeZone, isDollar }) => {
  const [options, setOptions] = useState();
  const [allSeries, setAllSeries] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('installs');
  const [selectedCampaigns, setSelectedCampaigns] = useState(new Set());
  const chartRef = useRef(null);

  // Zoom prevention effect
  useEffect(() => {
    const chartElement = chartRef.current;
    if (chartElement) {
      const preventZoom = (e) => {
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
        }
      };

      const preventWheelZoom = (e) => {
        if (e.ctrlKey) {
          e.preventDefault();
        }
      };

      document.addEventListener('wheel', preventWheelZoom, { passive: false });
      document.addEventListener('keydown', preventZoom);

      return () => {
        document.removeEventListener('wheel', preventWheelZoom);
        document.removeEventListener('keydown', preventZoom);
      };
    }
  }, []);

  // Main chart data processing effect
  useEffect(() => {
    if (!wowReportData || wowReportData.length === 0) return;

    // Initialize selected campaigns if none selected
    let campaignsToUse = selectedCampaigns;
    if (selectedCampaigns.size === 0) {
      campaignsToUse = new Set(wowReportData.map((item) => item.campaign_name));
      setSelectedCampaigns(campaignsToUse);
    }

    // Sort data by week and year
    const sortedData = [...wowReportData].sort((a, b) => {
      if (a.year === b.year) {
        return parseInt(a.week_number) - parseInt(b.week_number);
      }
      return a.year - b.year;
    });

    // Group data by week-year
    const groupedData = sortedData.reduce((acc, item) => {
      const weekYear = `Week ${item.week_number}-${item.year}`;
      if (!acc[weekYear]) {
        acc[weekYear] = {
          metricData: {},
          media_cost: 0
        };
      }

      if (campaignsToUse.has(item.campaign_name)) {
        if (!acc[weekYear].metricData[item.campaign_name]) {
          acc[weekYear].metricData[item.campaign_name] = 0;
        }
        acc[weekYear].metricData[item.campaign_name] += item[selectedMetric];
        acc[weekYear].media_cost += isDollar ? item.media_cost : item.media_cost_local_currency;
      }

      return acc;
    }, {});

    const weeks = Object.keys(groupedData);

    // Create bar series for metrics with explicit left y-axis assignment
    const metricSeries = Object.keys(groupedData[weeks[0]]?.metricData || {}).map((campaignName) => ({
      name: campaignName,
      type: 'column', // Changed to 'bar' instead of 'column'
      data: weeks.map(w => groupedData[w]?.metricData[campaignName] || 0)
    }));

    // Create line series for media cost (will use right y-axis)
    const mediaCostSeries = {
      name: 'Media Cost',
      type: 'line',
      // yAxisIndex: 1, // Explicitly use right y-axis
      data: weeks.map(w => groupedData[w]?.media_cost || 0)
    };

    // Calculate max values for axis scaling
    const metricMax = Math.max(...metricSeries.map(s => Math.max(...s.data)));
    const mediaCostMax = Math.max(...mediaCostSeries.data);

    // Calculate appropriate tick intervals
    const metricInterval = Math.ceil(metricMax / 5); // Show ~5 ticks on metric axis
    const mediaCostInterval = Math.ceil(mediaCostMax / 5); // Show ~5 ticks on cost axis

    const optionsDetails = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: [4, ...Array(metricSeries.length).fill(0)], // Thicker line for media cost
        curve: 'smooth'
      },
      fill: {
        opacity: [1, ...Array(metricSeries.length).fill(0.85)]
      },
      dataLabels: {
        enabled: false
      },
      labels: weeks,
      markers: {
        size: [4, ...Array(metricSeries.length).fill(0)] // Markers only for line
      },
      xaxis: {
        type: 'category',
        title: {
          text: 'Week - Year'
        }
      },
      yaxis: [
        {
          // Left y-axis for metrics
          seriesName: selectedMetric,
          title: {
            text: selectedMetric.charAt(0).toUpperCase() + selectedMetric.slice(1)
          },
          min: 0,
          max: metricMax * 1.2,
          tickAmount: 5,
          axisBorder: {
            show: true,
            color: '#1b1bfe'
          },
          labels: {
            formatter: (value) => formatNumberAbbreviated(value)
          }
        },
        {
          // Right y-axis for media cost
          opposite: true,
          seriesName: 'Media Cost',
          title: {
            text: 'Media Cost'
          },
          min: 0,
          max: mediaCostMax * 1.2,
          tickAmount: 5,
          axisBorder: {
            show: true,
            color: '#1b1bfe'
          },
          labels: {
            formatter: (value) => formatNumberAbbreviated(value)
          }
        }
      ],
      plotOptions: {
        bar: {
          columnWidth: '70%',
          distributed: false
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value, { seriesIndex, w }) {
            return `${formatNumberAbbreviated(value)} ${w.config.series[seriesIndex].type === 'line' ? '(Media Cost)' : `(${selectedMetric})`
              }`;
          }
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      },
      colors: [
        '#1b1bfe', // Blue for media cost line
        '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFD700', '#00CED1', '#8A2BE2', '#DC143C', '#FF8C00'
      ]
    };

    // Important: Set the series in specific order and explicitly assign axes
    const allSeriesData = [
      { ...mediaCostSeries, yAxisIndex: 1 }, // Media cost on right axis
      ...metricSeries.map(series => ({
        ...series,
        yAxisIndex: 0 // Metric bars on left axis
      }))
    ];

    setOptions(optionsDetails);
    setAllSeries(allSeriesData);
  }, [wowReportData, selectedMetric, isDollar, selectedCampaigns]);

  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Week-On-Week Overview</Typography>
        </Grid>

        {loading ? (
          <div style={{ height: '200px', width: '100%' }}>
            <LoaderCircular show={true} />
          </div>
        ) : allSeries.length === 0 || wowReportData.length === 0 ? (
          <div className="performanceDataContainer" ref={chartRef}>
            <Typography style={{ textAlign: 'center' }}>
              {error.length !== 0 ? (
                'No Data Found'
              ) : (
                <span>Please click <b>Apply Filter</b> to view data</span>
              )}
            </Typography>
          </div>
        ) : (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12} alignContent="center" sx={{ textAlign: 'center' }}>
              <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h3">Metrics</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="standard-select-currency"
                    select
                    value={selectedMetric}
                    onChange={(e) => setSelectedMetric(e.target.value)}
                  >
                    {metricsLabels.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="performanceMainContainer" ref={chartRef}>
                <Chart
                  options={options}
                  series={allSeries}
                  type="line"
                  width="100%"
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </MainCard>
  );
};

const mapStateToProps = (state) => ({
  wowReportData: state.wowReducer.wowReportData,
  loading: state.wowReducer.loading,
  error: state.wowReducer.error,
  timeZone: state.advertiserReducer.timeZone,
  isDollar: state.campaignReducer.isDollar
});

export default connect(mapStateToProps, null)(WowNonSkanView);