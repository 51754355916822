import axios from 'axios';
import {
  FETCH_IAP_DATA,
  FETCH_IAP_REQUEST,
  FETCH_IAP_RESET,
  FETCH_IAP_REQUEST_ERROR,
  FETCH_IAP_GRAPH_DATA,
  SET_STATIC_WIDZARDS_DATA_IAP
} from './iapTypes';
const initialState = {
  loading: false,
  iapData: [],
  graphData: [],
  error: '',
  error_msg: '',
  totalInstalls: 0,
  totalMediaCost: 0,
  totalClicks: 0,
  totalEvents: 0,
  totalMediaCostLocalCurrency: 0
};
const IAPReducer = (state = initialState, action) => {
  //console.log('action.payload', action.type, action.payload);
  switch (action.type) {
    case FETCH_IAP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_IAP_DATA:
      return {
        ...state,
        loading: false,
        iapData: action.payload
      };
    case FETCH_IAP_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        iapData: [],
        error_msg: 'Sorry no data found',
        error: action.payload
      };
    case FETCH_IAP_RESET:
      return {
        ...state,
        loading: false,
        iapData: [],
        graphData: [], // Clear graphData
        totalImpressions: 0,
        totalClicks: 0,
        totalMediaCost: 0,
        totalInstalls: 0,
        totalMediaCostLocalCurrency: 0
      };
    case FETCH_IAP_GRAPH_DATA:
      return {
        ...state,
        loading: false,
        graphData: action.payload
      };
    case SET_STATIC_WIDZARDS_DATA_IAP:
      return {
        ...state,
        loading: false,
        totalImpressions: action.payload.impressions,
        totalClicks: action.payload.clicks,
        totalMediaCost: action.payload.media_cost,
        totalInstalls: action.payload.installs,
        totalMediaCostLocalCurrency: action.payload.media_cost_local_currency
      };
    default:
      return state;
  }
};
export default IAPReducer;
