import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { Typography, Grid, Paper } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import DataCard from 'ui-component/cards/DataCard';
import SearchBar from 'components/Dashboard/SearchBar';
import { styled, useTheme } from '@mui/material/styles';
import { SET_SIDE_MENU } from 'store/actions';
import LoaderCustom from 'ui-component/LoaderCustom';
import CreativeDataNonSkanView from 'components/Creative/CreativeDataNonSkanView';
import CreativeDataSkanView from 'components/Creative/CreativeDataSkanView';
const Creative = ({ isSkan, timeZone, advertiserList, loading, advertiser_campaign_fetching }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('showSideMenu', true);
    dispatch({ type: SET_SIDE_MENU, showSideMenu: true });
  }, []);
  return (
    <>
      {advertiserList.length == 0 || advertiser_campaign_fetching == true ? (
        <LoaderCustom />
      ) : (
        <>
          <Paper elevation spacing={gridSpacing} className="stickyBox">
            <SearchBar />
          </Paper>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {timeZone === "JST" ?
                    (isSkan === true ? (<CreativeDataNonSkanView />) : (<CreativeDataNonSkanView />))
                    : isSkan === true ? (<CreativeDataSkanView />) : (<CreativeDataNonSkanView />)
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.creativeReducer.creativeLoading,
    creativeData: state.creativeReducer.creativeData,
    isSkan: state.campaignReducer.isSkan,
    userEmail: state.userReducer.userEmail,
    advertiserList: state.advertiserReducer.advertiser_list,
    selected_campaigns: state.campaignReducer.campaign_list,
    advertiser_campaign_fetching: state.advertiserReducer.advertiser_campaign_fetching,
    timeZone: state.advertiserReducer.timeZone
  };
};

export default connect(mapStateToProps, null)(Creative);
