import { combineReducers } from 'redux';
import customization from '../store/customizationReducer';
import skanRateCardStoreReducer from './skanRateCardStore/skanRateCardStoreReducer';
import advertiserReducer from './advertiser/advertiserReducer';
import campaignReducer from './campaign/campaignReducer';
import userReducer from './user/userReducer';
import reportingReducer from './reporting/reportingReducer';
import creativeReducer from './creative/creativeReducer';
import sidebarReducer from './sidebar/sidebarReducer';
import rawEventReducer from './rawEvent/rawEventReducer';
import wowReducer from './wow/wowReducer';
import IAPReducer from './iap/iapReducer';
const reducer = combineReducers({
  customization,
  skanRateCardStoreReducer,
  advertiserReducer,
  campaignReducer,
  userReducer,
  reportingReducer,
  creativeReducer,
  sidebarReducer,
  rawEventReducer,
  wowReducer,
  IAPReducer
});

export default reducer;
