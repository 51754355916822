import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, Grid, Button } from '@mui/material';
import { fetchIAMUserList } from '../../redux/user/userActions';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },

  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'center',
    headerAlign: 'center',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    headerAlign: 'center',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => (
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginRight: 16 }}
          //   onClick={() => handleEdit(params.row)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          //   onClick={() => handleDelete(params.row.id)}
        >
          Delete
        </Button>
      </div>
    )
  }
];
const ListUsers = ({ fetchIAMUserList, iamUserList, loading }) => {
  console.log(iamUserList);
  useEffect(() => {
    fetchIAMUserList();
  }, []);
  return (
    <MainCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <DataGrid
              rows={iamUserList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              loading={loading}
              pageSizeOptions={[10, 20, 30]}
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              style={{ minHeight: '400px', width: '100%' }}
              autoHeight
              className="custom-datagrid"
              getRowId={(row) => row.userId}
            />
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
    iamUserList: state.userReducer.iamUserList
  };
};
const mapDispatchToProps = {
  fetchIAMUserList: fetchIAMUserList
};
export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
